//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import ErrorCodesStore from '@/store/enums/ErrorCodesStore'

computed: {
    ...mapGetters('ErrorCodesStore', ['errorCodes'])
    },
methods:{
    ...mapActions('ErrorCodesStore', ['fetchErrorCodes']),
}
mounted() {   
  this.fetchErrorCodes()

*/

@Module({ namespaced: true })
export default class ErrorCodesStore extends VuexModule {
  ErrorCodes: any[] = []

  get errorCodes() {
    return this.ErrorCodes
  }

  @Mutation
  setErrorCodes(ErrorCodes: any) {
    this.ErrorCodes = ErrorCodes
  }

  @Action({ commit: 'setErrorCodes' })
  async fetchErrorCodes() {
    if (this.ErrorCodes.length == 0) {
      try {
        const result = await axios.get(apiURL + '/errorCodes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.ErrorCodes
    }
  }
}
