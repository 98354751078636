import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchContractStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchContractStore extends VuexModule {
  contractDate: string | null = null
  status: number | null = null
  validFrom: string | null = null
  validUntil: string | null = null
  producerNumber: number | null = null
  animalTypeId: number | null = null
  encashmentOrganisationAgisId: number | null = null

  items: TermItemDefinition[] = [
    { key: 'contractDate', operation: ':' },
    { key: 'status', operation: ':' },
    { key: 'validFrom', operation: ':' },
    { key: 'validUntil', operation: ':' },
    { key: 'producerNumber', operation: ':' },
    { key: 'animalTypeId', operation: ':' },
    { key: 'encashmentOrganisationAgisId', operation: ':' }
  ]
}
