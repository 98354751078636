import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '.'

/* https://github.com/championswimmer/vuex-module-decorators */

export class Farm {
  id = ''
  agisId = 0
  name = ''
}

@Module({ namespaced: true })
export default class OwnManagedFarmsStore extends VuexModule {
  farms: Farm[] = []

  @Mutation
  setFarms(farms: []) {
    this.farms = farms
  }

  get getFarms(): Farm[] {
    return this.farms
  }

  @Action({ commit: 'setFarms', rawError: true })
  async resetOwnManagedFarms() {
    return []
  }

  @Action({ commit: 'setFarms', rawError: true })
  async fetchOwnManagedFarms() {
    console.log('fetching farms')
    if (this.farms.length === 0) {
      try {
        const response = await axios.get(apiURL + '/legalEntities/' + store.state.PersonsStore.selectedPersonId + '/farms')
        if (await response.data) {
          return response.data
        }
      } catch (e) {
        showError(e)
      }
    } else {
      console.log('abort farm already loaded')

      return this.farms
    }
  }
}
