<template>
  <v-container>
    <v-card class="mb-4" v-if="domainId !== 0">
      <v-card-title v-html="$t('home_legacy_title')"></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <p v-html="$t('home_legacy_text')"></p>
            <p v-if="disableSubsidyCreditor" v-html="$t('home_maintenance_message')"></p>
            <v-list>
              <v-list-item v-for="app in legacyApps" v-bind:key="app">
                <v-btn color="primary" class="mr-3" :disabled="!legacyEnabled" v-on:click="gotoLegacyApp(app)">
                  {{ $t('home_legacy_button_' + app) }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col v-if="legacyPis">
            <v-btn icon v-on:click="gotoLegacyApp('pis')" height="240" width="360">
              <v-img src="../assets/images/pis_gt.png" alt="pis" height="240" width="360" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-4">
      <v-card-title v-html="$t('home_info_title')"></v-card-title>
      <v-card-text>
        <p v-html="$t('home_info_text')"></p>
      </v-card-text>
    </v-card>

    <v-card v-if="privileges.has({ path: '/issues', permission: 'read' })" class="mb-4">
      <v-card-title v-html="$t('home_info_issues')"></v-card-title>
      <v-card-text>
        <issuePreview></issuePreview>
      </v-card-text>
    </v-card>

    <div v-if="$getBeta()" class="beta">Dies ist ein Betafeature, only visible when ?beta=true</div>
  </v-container>
</template>
<script>
import { apiURL } from '@/main'
import { mapGetters } from 'vuex'
import issuePreview from '@/components/issues/issuePreview'
import { authService } from '@/services/auth.service'
import jwt_decode from 'jwt-decode'
import { showError } from '@/services/axios'

export default {
  name: 'home',
  methods: {
    gotoLegacyApp(app) {
      var decoded = jwt_decode(authService.loadedUser.access_token) // sec
      let now = new Date().getTime() // ms
      if (decoded.exp * 1000 < now) {
        this.$store.reset()
        authService.logout()
      } else if (app === 'pis') {
        this.$gotoLegacy('dbp', 365)
      } else {
        this.$gotoLegacy(app)
        /*const baseUrl = location.hostname === 'prod.dbmilch.ch' || location.hostname === 'prod.bdlait.ch' ? 'https://legacy.dbmilch.ch/' : 'https://test.dbmilch.ch/'
        if (this.legacyEnabled) window.open(baseUrl + app + '/index.cfm?action=login&domainID=' + this.domainId + '&jwt=' + authService.loadedUser.access_token, '_blank')*/
      }
    },
    async getDisableSubsidyCreditorFromSubsidyConfig() {
      try {
        const response = await this.axios.get(apiURL + '/subsidyConfigs/disableSubsidyCreditor/1')
        const data = await response.data
        this.disableSubsidyCreditor = data
      } catch (e) {
        console.log('e', e)
        showError(e)
      }
    }
  },
  data() {
    return {
      disableSubsidyCreditor: false
    }
  },
  computed: {
    ...mapGetters('SettingsStore', ['debug', 'OIDC']),
    //...mapGetters('PersonsStore', ['getSelectedPersonId', 'hasDomainSwitch']),
    domainId() {
      return this?.$store?.state?.PersonsStore?.selectedPersonId
    },
    originId() {
      return this?.$store?.state?.PersonsStore?.originLegalEntityId
    },
    legacyApps() {
      return this?.$store?.state?.Privileges?.legacyApps.filter((app) => app !== 'pis')
    },
    legacyPis() {
      return this?.$store?.state?.Privileges?.legacyApps.filter((app) => app === 'pis').length && this.legacyEnabled
    },
    legacyEnabled() {
      // Legacy-Access only with Agate-Token and without Domain-Switch
      return this.OIDC === 'agate' && this.domainId === this.originId
      //return this.OIDC === 'agate' && !this.hasDomainSwitch
    }
  },
  components: { issuePreview },
  mounted() {
    if (this.privileges.has({ path: '/subsidyConfigs', permission: 'disableSubsidyCreditor' })) {
      this.getDisableSubsidyCreditorFromSubsidyConfig()
    }
  }
}
</script>
