import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchLegalEntityStore extends VuexModule {
  LegalEntity_uid: string | null = null
  LegalEntity_agisId: number | null = null
  LegalEntity_egid: number | null = null
  LegalEntity_status: number | null = null
  useTerms = true
  items: TermItemDefinition[] = [
    { key: 'LegalEntity_uid', operation: '~' },
    { key: 'LegalEntity_agisId', operation: ':' },
    { key: 'LegalEntity_egid', operation: ':' },
    { key: 'LegalEntity_status', operation: ':', itemType: 'number' }
  ]
}
