import { TermItemDefinition } from '@/services/term'
import moment from 'moment'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */

export class PostcodeCluster {
  codes = ''
  farmCount = 0
  id = 0
  validFrom = ''
}

@Module({ namespaced: true })
export default class SearchMilkQualityBenchmarkStore extends VuexModule {
  farmId: number | null = null
  from: string | null = moment().add(-2, 'years').startOf('month').format('YYYY-MM-DD')
  until: string | null = moment().endOf('month').format('YYYY-MM-DD')
  postcodeCluster: null | PostcodeCluster = null

  items: TermItemDefinition[] = [
    { key: 'farmId', operation: ':' },
    { key: 'from', operation: '>=' },
    { key: 'until', operation: '<=' }
  ]
}
