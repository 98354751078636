//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import FarmAccessTypesStore from '@/store/enums/FarmAccessTypesStore'

computed: {
    ...mapGetters('FarmAccessTypesStore', ['farmAccessTypes'])
    },
methods:{
    ...mapActions('FarmAccessTypesStore', ['fetchFarmAccessTypes']),
}
mounted() {   
  this.fetchFarmAccessTypes()

*/

@Module({ namespaced: true })
export default class FarmAccessTypesStore extends VuexModule {
  FarmAccessTypes: any[] = []

  get farmAccessTypes() {
    return this.FarmAccessTypes
  }

  @Mutation
  setFarmAccessTypes(FarmAccessTypes: any) {
    this.FarmAccessTypes = FarmAccessTypes
  }

  @Action({ commit: 'setFarmAccessTypes' })
  async fetchFarmAccessTypes() {
    if (this.FarmAccessTypes.length == 0) {
      try {
        const result = await axios.get(apiURL + '/farmAccessTypes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.FarmAccessTypes
    }
  }
}
