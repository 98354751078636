//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
import FeedingsStore from '@/store/enums/FeedingsStore'

computed: {
    ...mapGetters('FeedingsStore', ['feedings'])
    },
methods:{
    ...mapActions('FeedingsStore', ['fetchFeedings']),
}
mounted() {   
  this.fetchFeedings()

*/

@Module({ namespaced: true })
export default class FeedingsStore extends VuexModule {
  Feedings: any[] = []

  get feedings() {
    return this.Feedings
  }

  @Mutation
  setFeedings(Feedings: any) {
    this.Feedings = Feedings
  }

  @Action({ commit: 'setFeedings' })
  async fetchFeedings() {
    if (this.Feedings.length == 0) {
      try {
        const result = await axios.get(apiURL + '/feedings')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.Feedings
    }
  }
}
