export const protocol: string = process.env.NODE_ENV === 'development' ? 'http://' : 'https://'
export const apiURL: string = process.env.NODE_ENV === 'development' ? '/api' : 'https://' + location.hostname + '/api'
export const idpURL: string = process.env.NODE_ENV === 'development' ? '/auth' : 'https://' + location.hostname + '/auth'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'bootstrap' //js files
import '@/services/axios'
import '@/components/toaster'

import Format from '@/plugins/format'
import Rules from '@/plugins/rules'
import Privileges from './plugins/privileges'
import moment from 'vue-moment'
require('moment/locale/de')

Vue.use(Format)
Vue.use(Globals)
Vue.use(Privileges)
Vue.use(Rules)
Vue.use(moment)

import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
More(Highcharts)
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

/* i18n wird im Router geladen wenn die Route zum ersten mal auf /de gepusht wird */
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  silentFallbackWarn: true,
  silentTranslationWarn: true
})

import vuetify from '@/plugins/vuetify' // path to vuetify export
import Globals from './plugins/globals'

Vue.config.productionTip = false

const vueApp = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
})

vueApp.$mount('#app')

export { vueApp }
