import { TermItemDefinition } from '@/services/term'
import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */

@Module({ namespaced: true })
export default class SearchIssuesStore extends VuexModule {
  issues_type: string | null = null
  issues_status: string | null = null
  issues_subject: string | null = null
  issues_sender: string | null = null
  issues_receiver: string | null = null
  issues_sender_id: number | null = null
  issues_receiver_id: number | null = null
  issues_assigned_person: number | null = null

  items: TermItemDefinition[] = [
    { key: 'issues_type', operation: ':' },
    { key: 'issues_status', operation: ':' },
    { key: 'issues_subject', operation: ':' },
    { key: 'issues_sender', operation: ':' },
    { key: 'issues_receiver', operation: ':' },
    { key: 'issues_sender_id', operation: ':' },
    { key: 'issues_receiver_id', operation: ':' },
    { key: 'issues_assigned_person', operation: ':' }
  ]
}
