//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class TranslationsStore extends VuexModule {
  de: object = {}
  fr: object = {}
  it: object = {}

  get getTranslationDe() {
    return this.de
  }

  get getTranslationFr() {
    return this.fr
  }

  get getTranslationIt() {
    return this.it
  }

  @Mutation
  setTranslationDe(languageObject: object) {
    this.de = languageObject
  }

  @Mutation
  setTranslationFr(languageObject: object) {
    this.fr = languageObject
  }

  @Mutation
  setTranslationIt(languageObject: object) {
    this.it = languageObject
  }
}
