import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchSegmentationFormStore extends VuexModule {
  SegmentationForm_id: number | null = null
  SegmentationForm_status: number | null = null
  SegmentationForm_periodeYear: string | null = null
  SegmentationForm_periodeMonth: string | null = null
  SegmentationForm_validUntil: string | null = null
  SegmentationForm_correction: string | null = null
  SegmentationForm_code: string | null = null

  items: TermItemDefinition[] = [
    { key: 'SegmentationForm_id', operation: ':' },
    { key: 'SegmentationForm_status', operation: ':' },
    { key: 'SegmentationForm_periodeYear', operation: ':' },
    { key: 'SegmentationForm_periodeMonth', operation: ':' },
    { key: 'SegmentationForm_validUntil', operation: ':' },
    { key: 'SegmentationForm_correction', operation: ':' },
    { key: 'SegmentationForm_code', operation: ':' }
  ]
}
