import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchContactStore extends VuexModule {
  Contact_name1: string | null = null
  Contact_name2: string | null = null
  Contact_zip: string | null = null
  Contact_locality: string | null = null
  Contact_phone: string | null = null
  Contact_addition: string | null = null
  Contact_address1: string | null = null
  Contact_email: string | null = null
  Contact_languageId: number | null = null
  Contact_country: string | null = null
  useTerms = true
  items: TermItemDefinition[] = [
    { key: 'Contact_name1', operation: '~' },
    { key: 'Contact_name2', operation: '~' },
    { key: 'Contact_zip', operation: '~' },
    { key: 'Contact_locality', operation: '~' },
    { key: 'Contact_phone', operation: '~' },
    { key: 'Contact_addition', operation: '~' },
    { key: 'Contact_address1', operation: '~' },
    { key: 'Contact_email', operation: '~' },
    { key: 'Contact_languageId', operation: ':' },
    { key: 'Contact_country', operation: ':' }
  ]
}
