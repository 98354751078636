//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { privileges } from '@/services/privileges'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
import ReportingObligationsStore from '@/store/enums/ReportingObligationsStore'

computed: {
    ...mapGetters('ReportingObligationsStore', ['reportingObligations'])
    },
methods:{
    ...mapActions('ReportingObligationsStore', ['fetchReportingObligations']),
}
mounted() {   
  this.fetchReportingObligations()
*/

@Module({ namespaced: true })
export default class ReportingObligationsStore extends VuexModule {
  ReportingObligations: any[] = []

  get reportingObligations() {
    return this.ReportingObligations
  }

  @Mutation
  setReportingObligations(ReportingObligations: any) {
    this.ReportingObligations = ReportingObligations
  }

  @Action({ commit: 'setReportingObligations' })
  async fetchReportingObligations() {
    /* check privileges, call of unprivileged api leads to an error */
    if (this.ReportingObligations.length === 0 && privileges.has({ path: '/reportingObligations', permission: 'read' })) {
      try {
        const result = await axios.get(apiURL + '/reportingObligations')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.ReportingObligations
    }
  }
}
