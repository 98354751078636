//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import InhibitorsStore from '@/store/enums/InhibitorsStore'

computed: {
    ...mapGetters('InhibitorsStore', ['inhibitors'])
    },
methods:{
    ...mapActions('InhibitorsStore', ['fetchInhibitors']),
}
mounted() {   
  this.fetchInhibitors()

*/

@Module({ namespaced: true })
export default class InhibitorsStore extends VuexModule {
  Inhibitors: any[] = []

  get inhibitors() {
    return this.Inhibitors
  }

  @Mutation
  setInhibitors(Inhibitors: any) {
    this.Inhibitors = Inhibitors
  }

  @Action({ commit: 'setInhibitors' })
  async fetchInhibitors() {
    if (this.Inhibitors.length == 0) {
      try {
        const result = await axios.get(apiURL + '/inhibitors')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.Inhibitors
    }
  }
}
