import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

import Vue from 'vue'
import Vuex from 'vuex'
import PersonsStore from './personsStore'
import Privileges from './privileges'
import Toast from './toast'
import SettingsStore from './SettingsStore'

import axios from '../services/axios'
import AuthenticationStore from './AuthenticationStore'
import SearchApplicationUserStore from './SearchApplicationUserStore'
import SearchIssuesStore from './SearchIssuesStore'
import SearchLegalEntityStore from './SearchLegalEntityStore'
import SearchSegmentationRelationStore from './SearchSegmentationRelationStore'
import SearchLabelAffiliationStore from './SearchLabelAffiliationStore'
import SearchLabelStore from './SearchLabelStore'
import LabelTypesStore from '@/store/enums/LabelTypesStore'
import SearchLabelVisualizationStore from './SearchLabelVisualizationStore'
import SearchSegmentationFormStore from './SearchSegmentationFormStore'
import SearchSegmentationStore from './SearchSegmentationStore'
import SearchFarmStore from './SearchFarmStore'
import SearchContactStore from './SearchContactStore'
import SearchContactPickerStore from './SearchContactPickerStore'
import SearchLocalUnitStore from './SearchLocalUnitStore'
import SearchDairyStore from './SearchDairyStore'
import SearchProcessingFormTemplateStore from './SearchProcessingFormTemplateStore'
import SearchProcessingFormStore from './SearchProcessingFormStore'
import SearchMilkQualityBenchmarkStore from './SearchMilkQualityBenchmarkStore'
import SearchProcessingRowStore from './SearchProcessingRowStore'
import SearchProcessingGroupingStore from './SearchProcessingGroupingStore'
import SearchOrganisationStore from './SearchOrganisationStore'
import SearchWarningStore from './SearchWarningStore'
import SearchTranslationStore from './SearchTranslationStore'
import SearchSubsidyInvoiceStore from './SearchSubsidyInvoiceStore'
import SearchSapInvoiceStore from './SearchSapInvoiceStore'
import SearchSubsidyGroupingStore from './SearchSubsidyGroupingStore'
import SearchSegmentationGroupingStore from './SearchSegmentationGroupingStore'
import SearchMasterdataStore from './SearchMasterdataStore'
import ApplicationRolesStore from '@/store/enums/ApplicationRolesStore'
import FarmTypesStore from '@/store/enums/FarmTypesStore'
import ProductionAreasStore from '@/store/enums/ProductionAreasStore'
import ProductionZonesStore from '@/store/enums/ProductionZonesStore'
import CantonsStore from '@/store/enums/CantonsStore'
import ContactOrganisationsStore from '@/store/enums/ContactOrganisationsStore'
import CountriesStore from '@/store/enums/CountriesStore'
import IssueStatusesStore from '@/store/enums/IssueStatusesStore'
import IssueTypesStore from '@/store/enums/IssueTypesStore'
import FarmAccessTypesStore from '@/store/enums/FarmAccessTypesStore'
import FarmAccessStatusesStore from '@/store/enums/FarmAccessStatusesStore'
import ErrorCodesStore from '@/store/enums/ErrorCodesStore'
import InhibitorsStore from '@/store/enums/InhibitorsStore'
import SampleStatusStore from '@/store/enums/SampleStatusStore'
import ThresholdExeededStore from '@/store/enums/ThresholdExeededStore'
import DeliveryStopStore from '@/store/enums/DeliveryStopStore'
import CountryRegionsStore from '@/store/enums/CountryRegionsStore'
import LanguagesStore from '@/store/enums/LanguagesStore'
import DairyTypesStore from '@/store/enums/DairyTypesStore'
import DairyProcessingsStore from '@/store/enums/DairyProcessingsStore'
import ResponsibilityGroupsStore from '@/store/enums/ResponsibilityGroupsStore'
import ReportingObligationsStore from '@/store/enums/ReportingObligationsStore'
import SegmentationStatusStore from '@/store/enums/SegmentationStatusStore'
import SegmentationTypesStore from '@/store/enums/SegmentationTypesStore'
import SubsidyTypesStore from '@/store/enums/SubsidyTypesStore'
import ProductionFormStatusStore from '@/store/enums/ProductionFormStatusStore'
import FormStatusStore from '@/store/enums/FormStatusStore'
import FeedingsStore from '@/store/enums/FeedingsStore'
import LabelBundleStore from '@/store/enums/LabelBundleStore'
import FormPeriodsStore from '@/store/enums/FormPeriodsStore'
import OwnManagedFarmsStore from './OwnManagedFarmsStore'
import OrganisationsStore from '@/store/enums/OrganisationsStore'
import AnimalTypesStore from '@/store/enums/AnimalTypesStore'
import MilkLabelsStore from '@/store/enums/MilkLabelsStore'
import MilkQualitySampleTypesStore from '@/store/enums/MilkQualitySampleTypesStore'
import MilkQualityDeliveryStopStatusesStore from '@/store/enums/MilkQualityDeliveryStopStatusesStore'
import SubsidyAccountsStore from '@/store/enums/SubsidyAccountsStore'
import SubsidyOrdersStore from '@/store/enums/SubsidyOrdersStore'
import SubsidyInvoiceStatusesStore from '@/store/enums/SubsidyInvoiceStatusesStore'
import SearchProductionStore from '@/store/SearchProductionStore'
import SearchProductionOverviewStore from '@/store/SearchProductionOverviewStore'
import SearchRebookingPeriodStore from '@/store/SearchRebookingPeriodStore'
import SearchDocumentStore from '@/store/SearchDocumentStore'
import DocumentTypeGroupsStore from '@/store/enums/DocumentTypeGroupsStore'
import SearchContractStore from '@/store/SearchContractStore'
import SearchContactAttributesRelationsStore from '@/store/SearchContactAttributesRelationsStore'
import SearchContactAttributesRelationsPickerStore from '@/store/SearchContactAttributesRelationsPickerStore'
import SearchProductionReportStore from '@/store/SearchProductionReportStore'

import { createStore } from 'vuex-extensions'
import TranslationsStore from '@/store/TranslationsStore'
//import DocumentTypesStore from '@/store/enums/DocumentTypesStore'
import SearchMilkQualityPeriodStore from './SearchMilkQualityPeriodStore'
import SearchMilkQualityTypeStore from './SearchMilkQualityTypeStore'
import SearchMilkQualityValueStore from './SearchMilkQualityValueStore'
import SearchMilkQualityRatingStore from './SearchMilkQualityRatingStore'
import SearchMilkQualityContentStore from './SearchMilkQualityContentStore'
import OwnManagedDairiesStore from '@/store/OwnManagedDairiesStore'
import SearchMessageStore from '@/store/SearchMessageStore'
import MessageTypesStore from '@/store/enums/MessageTypesStore'
import SearchMessageSentStore from '@/store/SearchMessageSentStore'

Vue.use(Vuex)

const store = createStore(Vuex.Store, {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Toast,
    AuthenticationStore,
    ApplicationRolesStore,
    CantonsStore,
    ContactOrganisationsStore,
    DairyTypesStore,
    DairyProcessingsStore,
    DocumentTypeGroupsStore,
    ResponsibilityGroupsStore,
    ReportingObligationsStore,
    SegmentationStatusStore,
    SegmentationTypesStore,
    SubsidyTypesStore,
    CountriesStore,
    IssueStatusesStore,
    IssueTypesStore,
    FarmAccessTypesStore,
    FarmAccessStatusesStore,
    LabelBundleStore,
    LabelTypesStore,
    ErrorCodesStore,
    InhibitorsStore,
    SampleStatusStore,
    ThresholdExeededStore,
    DeliveryStopStore,
    CountryRegionsStore,
    LanguagesStore,
    FarmTypesStore,
    MessageTypesStore,
    ProductionAreasStore,
    ProductionZonesStore,
    PersonsStore,
    Privileges,
    SettingsStore,
    SearchApplicationUserStore,
    SearchIssuesStore,
    SearchLegalEntityStore,
    SearchFarmStore,
    SearchContactStore,
    SearchContactPickerStore,
    SearchContactAttributesRelationsStore,
    SearchContactAttributesRelationsPickerStore,
    SearchLocalUnitStore,
    SearchDairyStore,
    SearchProductionStore,
    SearchProductionOverviewStore,
    SearchRebookingPeriodStore,
    SearchProcessingFormTemplateStore,
    SearchProcessingFormStore,
    SearchMilkQualityBenchmarkStore,
    SearchProcessingRowStore,
    SearchProcessingGroupingStore,
    SearchSapInvoiceStore,
    SearchSubsidyGroupingStore,
    SearchSegmentationGroupingStore,
    SearchMasterdataStore,
    SearchOrganisationStore,
    SearchWarningStore,
    ProductionFormStatusStore,
    FormStatusStore,
    FeedingsStore,
    FormPeriodsStore,
    OwnManagedFarmsStore,
    OwnManagedDairiesStore,
    OrganisationsStore,
    AnimalTypesStore,
    MilkLabelsStore,
    MilkQualitySampleTypesStore,
    MilkQualityDeliveryStopStatusesStore,
    SearchTranslationStore,
    SearchSubsidyInvoiceStore,
    SubsidyAccountsStore,
    SubsidyOrdersStore,
    SubsidyInvoiceStatusesStore,
    SearchSegmentationRelationStore,
    SearchLabelAffiliationStore,
    SearchLabelStore,
    SearchLabelVisualizationStore,
    SearchSegmentationFormStore,
    SearchSegmentationStore,
    SearchDocumentStore,
    TranslationsStore,
    SearchContractStore,
    SearchProductionReportStore,
    SearchMilkQualityPeriodStore,
    SearchMilkQualityTypeStore,
    SearchMilkQualityValueStore,
    SearchMilkQualityRatingStore,
    SearchMilkQualityContentStore,
    SearchMessageSentStore,
    SearchMessageStore
  },
  plugins: [vuexLocal.plugin]
})

export default store
