import Toasted from 'vue-toasted'
import Vue from 'vue'
import { toast } from '../services/toast'
import { i18n } from '../main'

Vue.use(Toasted)

Vue.toasted.register(
  'success',
  (payload) => {
    if (!payload.message) {
      return i18n.t('toast_generic_success_message')
    }
    toast.addToast(payload)
    return payload.message
  },
  {
    type: 'success',
    //icon: 'mdi-checkbox-marked-circle-outline',
    duration: 8000,
    position: 'bottom-right',
    className: 'toastWidth',
    fitToScreen: true
  }
)

Vue.toasted.register(
  'warning',
  (payload) => {
    if (!payload.message) {
      return i18n.t('toast_generic_warning_message')
    }
    toast.addToast(payload)
    return payload.message
  },
  {
    type: 'warning',
    //icon: 'mdi-alert-outline',
    duration: 8000,
    position: 'bottom-right',
    className: 'toastWidth',
    fitToScreen: true
  }
)

Vue.toasted.register(
  'error',
  (payload) => {
    if (!payload.message) {
      return i18n.t('toast_generic_error_message')
    }
    toast.addToast(payload)
    return payload.message
  },
  {
    type: 'error',
    //icon: 'mdi-alert-circle-outline',
    duration: 8000,
    position: 'bottom-right',
    className: 'toastWidth',
    fitToScreen: true
  }
)
