//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import DairyProcessingsStore from '@/store/enums/DairyProcessingsStore'

computed: {
    ...mapGetters('DairyProcessingsStore', ['dairyProcessings'])
    },
methods:{
    ...mapActions('DairyProcessingsStore', ['fetchDairyProcessings']),
}
mounted() {   
  this.fetchDairyProcessings()

*/

@Module({ namespaced: true })
export default class DairyProcessingsStore extends VuexModule {
  DairyProcessings: any[] = []

  get dairyProcessings() {
    return this.DairyProcessings
  }

  @Mutation
  setDairyProcessings(DairyProcessings: any) {
    this.DairyProcessings = DairyProcessings
  }

  @Action({ commit: 'setDairyProcessings' })
  async fetchDairyProcessings() {
    if (this.DairyProcessings.length == 0) {
      const result = await axios.get(apiURL + '/dairyProcessings')
      return await result.data
    } else {
      return this.DairyProcessings
    }
  }
}
