import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchLabelVisualizationStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchLabelVisualizationStore extends VuexModule {
  reduced: boolean | null = true
  withMarketedMilk: string | null = null
  receivedFrom: string | null = null
  receivedUntil: string | null = null

  items: TermItemDefinition[] = [
    { key: 'reduced', operation: ':' },
    { key: 'withMarketedMilk', operation: ':' },
    { key: 'receivedFrom', operation: ':' },
    { key: 'receivedUntil', operation: ':' }
  ]
}
