//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import SubsidyOrdersStore from '@/store/enums/SubsidyOrdersStore'

computed: {
    ...mapGetters('SubsidyOrdersStore', ['SubsidyOrders'])
    },
methods:{
    ...mapActions('SubsidyOrdersStore', ['fetchSubsidyOrders']),
}
mounted() {   
  this.fetchSubsidyOrders()

*/

@Module({ namespaced: true })
export default class SubsidyOrdersStore extends VuexModule {
  SubsidyOrders: any[] = []
  SubsidyOrdersProduction: any[] = []
  SubsidyOrdersProcessing: any[] = []

  get subsidyOrders() {
    return this.SubsidyOrders
  }

  get subsidyOrdersProduction() {
    return this.SubsidyOrdersProduction
  }

  get subsidyOrdersProcessing() {
    return this.SubsidyOrdersProcessing
  }

  @Mutation
  setSubsidyOrders(SubsidyOrders: any) {
    this.SubsidyOrders = SubsidyOrders
  }

  @Mutation
  setSubsidyOrdersProduction(SubsidyOrdersProduction: any) {
    this.SubsidyOrdersProduction = SubsidyOrdersProduction
  }

  @Mutation
  setSubsidyOrdersProcessing(SubsidyOrdersProcessing: any) {
    this.SubsidyOrdersProcessing = SubsidyOrdersProcessing
  }

  @Action({ commit: 'setSubsidyOrders' })
  async fetchSubsidyOrders() {
    if (this.SubsidyOrders.length === 0) {
      try {
        const result = await axios.get(apiURL + '/subsidyOrders')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SubsidyOrders
    }
  }

  @Action({ commit: 'setSubsidyOrdersProduction' })
  async fetchSubsidyOrdersProduction() {
    if (this.SubsidyOrdersProduction.length === 0) {
      try {
        const result = await axios.get(apiURL + '/subsidyOrders?subset=MARKETEDMILK')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SubsidyOrdersProduction
    }
  }

  @Action({ commit: 'setSubsidyOrdersProcessing' })
  async fetchSubsidyOrdersProcessing() {
    if (this.SubsidyOrdersProcessing.length === 0) {
      try {
        const result = await axios.get(apiURL + '/subsidyOrders?includeVirtual=true')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SubsidyOrdersProcessing
    }
  }
}
