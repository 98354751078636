import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
import LanguagesStore from '@/store/enums/LanguagesStore'

computed: {
    ...mapGetters('LanguagesStore', ['languages'])
    },
*/

@Module({ namespaced: true })
export default class LanguagesStore extends VuexModule {
  // ... axios.get(apiURL + '/languages')
  Languages: any[] = [
    { id: 1, nameDe: 'Deutsch', nameFr: 'Allemand', nameIt: 'Tedesco' },
    { id: 2, nameDe: 'Französisch', nameFr: 'Français', nameIt: 'Francese' },
    { id: 3, nameDe: 'Italienisch', nameFr: 'Italien', nameIt: 'Italiano' }
  ]

  get languages() {
    return this.Languages
  }
}
