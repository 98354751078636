//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
import ProductionFormStatusStore from '@/store/enums/ProductionFormStatusStore'

computed: {
    ...mapGetters('ProductionFormStatusStore', ['productionFormStatus'])
    },
methods:{
    ...mapActions('ProductionFormStatusStore', ['fetchProductionFormStatus']),
}
mounted() {   
  this.fetchProductionFormStatus()

*/

@Module({ namespaced: true })
export default class ProductionFormStatusStore extends VuexModule {
  ProductionFormStatus: any[] = []

  get productionFormStatus() {
    return this.ProductionFormStatus
  }

  @Mutation
  setProductionFormStatus(ProductionFormStatus: any) {
    this.ProductionFormStatus = ProductionFormStatus
  }

  @Action({ commit: 'setProductionFormStatus' })
  async fetchProductionFormStatus() {
    if (this.ProductionFormStatus.length === 0) {
      try {
        const result = await axios.get(apiURL + '/productionFormRows/status')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.ProductionFormStatus
    }
  }
}
