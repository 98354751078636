import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/*
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchSubsidyInvoiceStore extends VuexModule {
  SubsidyInvoiceRun_completionFrom: string | null = null
  SubsidyInvoiceRun_completionUntil: string | null = null
  SubsidyInvoiceRun_completion: string | null = null
  SubsidyInvoice_status: string | null = null
  SubsidyInvoice_id: number | null = null
  SubsidyInvoice_amountMin: number | null = null
  SubsidyInvoice_amountMax: number | null = null

  items: TermItemDefinition[] = [
    { key: 'SubsidyInvoiceRun_completionFrom', operation: '>=' },
    { key: 'SubsidyInvoiceRun_completionUntil', operation: '<' },
    { key: 'SubsidyInvoiceRun_completion', operation: ':' },
    { key: 'SubsidyInvoice_status', operation: ':' },
    { key: 'SubsidyInvoice_id', operation: ':' },
    { key: 'SubsidyInvoice_amountMin', operation: '>=' },
    { key: 'SubsidyInvoice_amountMax', operation: '<=' }
  ]
}
