<template></template>
<script>
export default {
  name: 'AppHeader',
  computed: {
    svglogode() {
      return require('../assets/images/dbmilchch_rgb.svg')
    },
    svglogofr() {
      return require('../assets/images/bdlaitch_rgb.svg')
    },
    favLogo() {
      return require('../assets/images/dbm_fav.png')
    }
  },
  mounted() {
    this.$nextTick(async () => {
      /* favicon und manifest.json */
      let baselink = '../assets/images/'
      var link = document.createElement('link')
      link.rel = 'shortcut icon'
      link.href = this.favLogo
      document.getElementsByTagName('head')[0].appendChild(link)

      var link2 = document.createElement('link')
      link2.sizes = '96x96'
      link2.rel = 'icon'
      link2.href = this.favLogo
      document.getElementsByTagName('head')[0].appendChild(link2)

      /* apfel mask-icon */
      var link3 = document.createElement('link')
      link3.rel = 'mask-icon'
      link3.href = this.favLogo
      document.getElementsByTagName('head')[0].appendChild(link3)

      /* IOS touch icon */
      var link4 = document.createElement('link')
      link4.rel = 'apple-touch-icon'
      link4.href = this.favLogo
      document.getElementsByTagName('head')[0].appendChild(link4)

      /*
      var mani = document.createElement('link')
      mani.rel = 'manifest'
      mani.href = './favicons/manifest' + to.params.lang + '.json'
      document.getElementsByTagName('head')[0].appendChild(mani)
      */
    })
  }
}
</script>
