import axios from './axios'
import { vueApp, apiURL } from '../main'

class Toast {
  private static _instance: Toast
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  addToast(toast: unknown) {
    vueApp.$store.commit('addToast', toast)
  }
}

export const toast = Toast.Instance
