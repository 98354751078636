import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchOrganisationStore', ['BuyerOrganisation_id'])
    },
 */
@Module({ namespaced: true })
export default class SearchOrganisationStore extends VuexModule {
  BuyerOrganisation_id: number | null = null
  EncashmentOrganisation_id: number | null = null

  items: TermItemDefinition[] = [
    { key: 'BuyerOrganisation_id', operation: ':' },
    { key: 'EncashmentOrganisation_id', operation: ':' }
  ]
}
