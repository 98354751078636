//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import IssueTypesStore from '@/store/enums/IssueTypesStore'

computed: {
    ...mapGetters('IssueTypesStore', ['issueTypes'])
    },
methods:{
    ...mapActions('IssueTypesStore', ['fetchIssueTypes']),
}
mounted() {   
  this.fetchIssueTypes()

*/

@Module({ namespaced: true })
export default class IssueTypesStore extends VuexModule {
  IssueTypes: any[] = []

  get issueTypes() {
    return this.IssueTypes
  }

  @Mutation
  setIssueTypes(IssueTypes: any) {
    this.IssueTypes = IssueTypes
  }

  @Action({ commit: 'setIssueTypes' })
  async fetchIssueTypes() {
    if (this.IssueTypes.length == 0) {
      try {
        const result = await axios.get(apiURL + '/issueTypes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.IssueTypes
    }
  }
}
