import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/*  status: string | null = null
 */
@Module({ namespaced: true })
export default class SearchSegmentationStore extends VuexModule {
  formId: number | null = null
  formCode: string | null = null
  segmentationType: string | null = null
  feeding: string | null = null
  status: string | null = null
  statusFrom: string | null = null
  statusTo: string | null = null
  validFrom: string | null = null
  validUntil: string | null = null
  periodId: number | null = null
  reportingObligation: string | null = null
  difference: number | null = null
  posNr: number | null = null
  dairyOutIdent: number | null = null
  dairyInIdent: number | null = null
  delimitationOnly: string | null = null
  modificationFrom: string | null = null
  modificationUntil: string | null = null
  correction: string | null = null

  items: TermItemDefinition[] = [
    { key: 'formId', operation: ':' },
    { key: 'formCode', operation: '~' },
    { key: 'segmentationType', operation: ':' },
    { key: 'feeding', operation: ':' },
    { key: 'status', operation: ':' },
    { key: 'statusFrom', operation: '>=' },
    { key: 'statusTo', operation: '<=' },
    { key: 'validFrom', operation: '>=' },
    { key: 'validUntil', operation: '<=' },
    { key: 'periodId', operation: ':' },
    { key: 'reportingObligation', operation: ':' },
    { key: 'difference', operation: ':' },
    { key: 'posNr', operation: ':' },
    { key: 'dairyOutIdent', operation: ':' },
    { key: 'dairyInIdent', operation: ':' },
    { key: 'delimitationOnly', operation: ':' },
    { key: 'modificationFrom', operation: '>=' },
    { key: 'modificationUntil', operation: '<=' },
    { key: 'correction', operation: ':' }
  ]
}
