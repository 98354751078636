<template>
  <div id="app" class="bootstrap">
    <v-app>
      <AppHeader />
      <div class="app-height">
        <navigation></navigation>
        <navigationbreadcrumb></navigationbreadcrumb>
        <DownloadInfoDialog></DownloadInfoDialog>

        <!-- @MARTIN AND MARK, beware!  $route.fullPath hat unerwünschte Nebenwirkungen (Die Komponenten werden sofort rerendered),
        $route.path funktioniert -->
        <!-- erst rendern wenn privileges geladen sind -->
        <router-view :v-if="ready" :key="$route.path" />
      </div>
      <div class="footer d-print-none">{{ getUpdatedImpressumText() }} ©</div>
    </v-app>
  </div>
</template>

<script>
import AppHeader from '@/views/AppHeader.vue'
import Navigation from './views/Navigation.vue'
import Navigationbreadcrumb from './views/Navigationbreadcrumb.vue'
import { apiURL, i18n } from '@/main'
import DownloadInfoDialog from './components/downloadInfoDialog.vue'
import { CustomFormatter } from '@/views/translations/CustomFormatter'

//import { translations } from "./de.json"

export default {
  components: {
    AppHeader: AppHeader,
    navigation: Navigation,
    navigationbreadcrumb: Navigationbreadcrumb,
    DownloadInfoDialog
  },
  data() {
    return {
      apiURL: apiURL + '/translations/language/'
    }
  },
  methods: {
    getUpdatedImpressumText() {
      const formatter = new CustomFormatter()
      return formatter.interpolate(i18n.t('impressum_text'), [new Date().getFullYear()])
    }
  },
  computed: {
    ready() {
      return this.$store.state.Privileges.privileges.length > 0
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/modules/_fonts.scss';
@import '@/styles/variables.scss';
@import '@/styles/customStyle.scss';

.bootstrap {
  @import '@/styles/bootstrap.scss';
}

.app-height {
  min-height: 85vh;
}

.footer {
  background-color: $dbm-highlighted-blue;
  color: $dbm-white;
  font-weight: $fw-light;
  text-align: right;
  height: $footer-height;
  line-height: $footer-height;
  margin-top: $spacer-xxl;
  padding: 0 15px;
}

.v-expansion-panels {
  padding-bottom: 14px !important; //layout fix for v-expansion-panels
}

.theme--light.v-application {
  color: $dbm-passiv-grey !important;
}

.theme--light.v-select .v-select__selections {
  color: rgba(0, 0, 0, 0.87);
}

.v-btn:not(.v-btn--outlined).secondary {
  color: $dbm-primary-blue !important;
  border: $solid-border solid $dbm-primary-blue !important;
  border-color: $dbm-primary-blue !important;
  border-width: $dbm-border-width !important;
}

.v-btn:not(.v-btn--outlined).primary:hover {
  background-color: $dbm-highlighted-blue !important;
  border-color: $dbm-highlighted-blue !important;
}
.v-btn:not(.v-btn--outlined).secondary:hover {
  color: $dbm-highlighted-blue !important;
  border: $solid-border solid $dbm-highlighted-blue !important;
  border-width: $dbm-border-width !important;
}

.theme--light.v-btn:hover::before {
  opacity: 0 !important;
}

.theme--light.v-tabs .v-tab:hover::before {
  opacity: 0 !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0 !important;
}

.v-tab.v-tab:hover {
  color: $dbm-highlighted-grey !important;
}

.v-tab.v-tab--active:hover {
  color: $dbm-highlighted-blue !important;
}

.spacer-md {
  margin-bottom: $spacer-md;
}

.v-input--is-readonly {
  opacity: 0.4;
}

.align-right {
  text-align: right;
}

/*Search mask*/

.bootstrap input,
.v-select__selection,
.align-right.link,
.v-label,
.v-messages {
  font-size: $font-input-em !important;
}

/*Icon*/

.v-icon svg {
  height: 20px;
  width: 20px;
}

/*button*/

.v-btn--is-elevated {
  box-shadow: none !important;
}

.v-btn {
  padding: 10px 14px 12px 14px !important;
  border-radius: $rounded-corners !important;
}

/*Menu*/

.bootstrap .dropdown-menu {
  border: none;
  padding: 0 0 3rem 0 !important;
}

.bootstrap .dropdown-menu[data-bs-popper] {
  margin-top: 1px;
}

/*Table*/
.v-data-table {
  margin-top: $spacer-lg;
}

table .v-textarea {
  background-color: $dbm-table-input-color !important;
}

.nomessage .v-messages,
.nomessage .v-text-field__details {
  display: none !important;
}

.nomessage .v-messages,
.nomessage .v-input__slot {
  margin-bottom: 0 !important;
}

tr:focus-within td {
  background-color: $dbm-light-grey !important;
}
/** Buttons have to be distanced */
.v-btn + .v-btn {
  margin-left: $spacer-xs;
}

h1 {
  font-weight: $fw-xx-light !important;
}

th {
  color: $dbm-header-color !important;
}

td {
  color: $dbm-primary-grey !important;
}

.toastWidth {
  max-width: 90vw !important;
  display: block !important;
  word-break: break-all !important;
}

/*disabled fields*/
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: $dbm-primary-grey !important;
}

.theme--light.v-label--is-disabled {
  color: $dbm-primary-grey !important;
}

.theme--light.v-select .v-select__selection--disabled {
  color: $dbm-primary-grey !important;
}

/**beta */
.beta {
  border: 1px solid $dbm-error-red !important;
  background-color: $dbm-warning !important;
}
</style>
