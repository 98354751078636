import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'
import moment from 'moment'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchLabelStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchLabelStore extends VuexModule {
  labelTypeId: number | null = null
  animalTypeId: number | null = 1
  complied: string | null = null
  dueDate: string | null = moment().format('YYYY-MM-DD')

  items: TermItemDefinition[] = [
    { key: 'labelTypeId', operation: ':' },
    { key: 'animalTypeId', operation: ':', defaultValue: 1 },
    { key: 'complied', operation: ':' },
    { key: 'dueDate', operation: ':', itemType: 'string', defaultValue: moment().format('YYYY-MM-DD') }
  ]
}
