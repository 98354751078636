/*import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
});
*/
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

// Translation provided by Vuetify (javascript)
import ja from 'vuetify/src/locale/ja'
import de from 'vuetify/src/locale/de'
import it from 'vuetify/src/locale/it'
import fr from 'vuetify/src/locale/fr'
import '@mdi/font/css/materialdesignicons.css'

// Translation provided by Vuetify (typescript)
import pl from 'vuetify/src/locale/pl'

Vue.use(Vuetify)

const opts: any = {
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { ja, de, it, fr, pl },
    current: location.pathname.split('/')[1]
  },
  theme: {
    themes: {
      light: {
        primary: '#00A1DB',
        secondary: '#FFFFFF',
        accent: '#00A1DB',
        error: '#b71c1c'
      }
    }
  }
}

export default new Vuetify(opts)
