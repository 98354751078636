import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchSubsidyGroupingStore extends VuexModule {
  groupByCreditor: string | null = null
  groupByDate: string | null = null
  groupByOrder: string | null = null
  groupByRun: string | null = null
  groupByInvoice: string | null = null
  groupByAccount: string | null = null
  groupByLocalUnit: string | null = null

  items: TermItemDefinition[] = [
    { key: 'groupByCreditor', operation: ':' },
    { key: 'groupByDate', operation: ':' },
    { key: 'groupByOrder', operation: ':' },
    { key: 'groupByRun', operation: ':' },
    { key: 'groupByInvoice', operation: ':' },
    { key: 'groupByAccount', operation: ':' },
    { key: 'groupByLocalUnit', operation: ':' }
  ]
}
