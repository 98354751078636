import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchProcessingFormStore extends VuexModule {
  ProcessingForm_id: number | null = null
  ProcessingForm_ident: number | null = null
  ProcessingForm_statusIdMin: number | null = null
  ProcessingForm_statusIdMax: number | null = null
  ProcessingForm_isCorrection: string | null = null
  ProcessingForm_validFrom: string | null = null
  ProcessingForm_validUntil: string | null = null
  ProcessingForm_periodId: number | null = null
  ProcessingForm_code: string | null = null
  ProcessingForm_milkLabel: string | null = null
  ProcessingForm_correction: number | null = null

  items: TermItemDefinition[] = [
    { key: 'ProcessingForm_id', operation: ':' },
    { key: 'ProcessingForm_ident', operation: ':' },
    { key: 'ProcessingForm_statusIdMin', operation: '>=', itemType: 'number' },
    { key: 'ProcessingForm_statusIdMax', operation: '<=', itemType: 'number' },
    { key: 'ProcessingForm_isCorrection', operation: ':', itemType: 'number' },
    { key: 'ProcessingForm_validFrom', operation: '>=' },
    { key: 'ProcessingForm_validUntil', operation: '<=' },
    { key: 'ProcessingForm_periodId', operation: ':' },
    { key: 'ProcessingForm_code', operation: ':' },
    { key: 'ProcessingForm_milkLabel', operation: ':' },
    { key: 'ProcessingForm_correction', operation: ':' }
  ]
}
