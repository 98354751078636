/*
Show a NavigationItem only in beta mode with:
set visibilityBeta: true on the Element
enable disable betafeatures with?beta=false
develop = beta = true
production = beta = false
*/
export const navTree = [
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Stammdaten',
      fr: 'Données de base',
      it: 'Dati principali'
    },
    level: 1,
    children: [
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Suche',
          fr: 'Recherche',
          it: 'Cerca'
        },
        level: 2,
        children: [
          {
            route: '/masterdata',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suche / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          },
          {
            route: '/masterdata/find',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Erweiterte Suche',
              fr: 'Recherche avancée',
              it: 'Ricerca avanzata'
            },
            level: 3,
            children: []
          },
          {
            route: '/masterdata',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Beta = true',
              fr: 'Beta = true',
              it: 'Beta = true'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Stammdaten',
          fr: 'Données de base',
          it: 'Dati principali'
        },
        level: 2,
        children: [
          {
            route: '/legalEntities/search',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Rechtl Einheit/Bewirt.',
              fr: 'Entité légale',
              it: 'Entità legale'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Lokale Einheit',
              fr: 'Unité locale',
              it: 'Unità locale'
            },
            level: 3,
            children: []
          },
          {
            route: '/farms',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Milchproduzenten',
              fr: 'Producteurs de lait',
              it: 'Produttori di latte'
            },
            level: 3,
            children: []
          },
          {
            route: '/dairies',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Milchverwerter',
              fr: 'Utilisateurs de lait',
              it: 'Processori di latte'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Organisationen',
              fr: 'Organisations',
              it: 'Organizzazioni'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertungen',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Statistik',
              fr: 'Statistiques',
              it: 'Statistiche'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Administration',
          fr: 'Administration',
          it: 'Amministrazione'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Gruppen',
              fr: 'Groupes',
              it: 'Gruppi'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Bewirtschafterwechsel',
              fr: "Changement d'exploitant",
              it: 'Cambiamento del gestore'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Differenzen',
              fr: 'Différences',
              it: 'Differenze'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Differenzdossiers',
              fr: 'Dossiers des différences',
              it: 'Dossier delle differenze'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Label',
      fr: 'Label',
      it: 'Label'
    },
    level: 1,
    children: [
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Label',
          fr: 'Label',
          it: 'Label'
        },
        level: 2,
        children: [
          {
            route: '/labelData',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Suchen',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Datenfreigaben',
          fr: 'Partage des données',
          it: 'Rilasci di dati'
        },
        level: 2,
        children: [
          {
            route: '/labelOrders',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Suche',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertungen',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Listen / Statistik',
              fr: 'Listes / Statistiques',
              it: 'Liste / Statistiche'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Administration',
          fr: 'Administration',
          it: 'Amministrazione'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: '…',
              fr: '…',
              it: '…'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Milchproduktion',
      fr: 'Production laitière',
      it: 'Produzione di latte'
    },
    level: 1,
    children: [
      {
        route: '/contracts',
        visibility: false,
        visibilityBeta: true,
        selectedPath: false,
        label: {
          de: 'Verträge',
          fr: 'Contrats',
          it: 'Contratti'
        },
        level: 2,
        children: [
          {
            route: '/contracts/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Suchen / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Mengen',
          fr: 'Quantités',
          it: 'Quantità'
        },
        level: 2,
        children: [
          {
            route: '/productionForms/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen / Melden',
              fr: 'Recherche / Annoncer',
              it: 'Cerca / Segnala'
            },
            level: 3,
            children: []
          },
          {
            route: '/productionForms/reports/overview/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'MPD1 Gesuchsübersicht',
              fr: 'MPD1 Aperçu des requêtes',
              it: 'MPD1 Panoramica delle richieste'
            },
            level: 3,
            children: []
          },
          {
            route: '/productionFormRows/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Vermarktete Milch',
              fr: 'Lait commercialisé',
              it: 'Latte commercializzato'
            },
            level: 3,
            children: []
          },
          {
            route: '/productionFormRows/farmer/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Vermarktete Milch',
              fr: 'Lait commercialisé',
              it: 'Latte commercializzato'
            },
            level: 3,
            children: []
          },
          {
            route: '/productionForms/multiEdit/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Umbuchen',
              fr: 'Umbuchen',
              it: 'Umbuchen'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Zulagen Verkehrsmilch',
          fr: 'Supplément lait commercialisée',
          it: 'Supplemento latte commercializzato'
        },
        level: 2,
        children: [
          {
            route: '/subsidyCreditors/production/editDialog',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Gesuch (Bankverbindung)',
              fr: 'Requête (données bancaires)',
              it: 'Richiesta (Dati bancari)'
            },
            level: 3,
            children: []
          },
          {
            route: '/subsidyInvoices/production',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Abrechnungen',
              fr: 'Décomptes',
              it: 'Conteggi'
            },
            level: 3,
            children: []
          },
          {
            route: '/productionFormRows/subsidyApproval',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Mengenfreigaben',
              fr: 'Autorisations de quantité',
              it: 'Rilasci di quantità'
            },
            level: 3,
            children: []
          },
          {
            //"route": "/subsidyConfigs?subsidyType=production",
            route: '/subsidyConfigs',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Verwaltung',
              fr: 'Administration',
              it: 'Amministrazione'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertungen',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Listen / Statistik',
              fr: 'Listes / Statistiques',
              it: 'Liste / Statistiche'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Administration',
          fr: 'Administration',
          it: 'Amministrazione'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Monatsabschluss',
              fr: 'Bouclement du mois',
              it: 'Chiusura del mese'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Milchprüfung',
      fr: 'Contrôle du lait',
      it: 'Prova del latte'
    },
    level: 1,
    children: [
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Analysen',
          fr: 'Analyses',
          it: 'Analisi'
        },
        level: 2,
        children: [
          {
            route: '/milkQualities/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Suchen (beta)',
              fr: 'Recherche (beta)',
              it: 'Cerca (beta)'
            },
            level: 3,
            children: []
          },
          {
            route: '/legacy/dbo/140',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          },
          {
            route: '/legacy/dbp/360',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          },
          {
            route: '/legacy/vis/540',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          },
          {
            route: '/milkQualities/content/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Bestellen',
              fr: 'Commander',
              it: 'Ordina'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Benchmark Harnstoff',
          fr: 'Benchmark urée',
          it: 'Benchmark urea'
        },
        level: 2,
        children: [
          {
            route: '/milkQualityBenchmarks/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Tabelle',
              fr: 'Tableau',
              it: 'Tabella '
            },
            level: 3,
            children: []
          },
          {
            route: '/milkQualityBenchmarks/chart',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Diagramm',
              fr: 'Diagramme',
              it: 'Diagramma'
            },
            level: 3,
            children: []
          },
          {
            route: '/milkQualityBenchmarks/infoPage',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Informationen',
              fr: 'Informations',
              it: 'Informazioni'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertung',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Listen / Statistik',
              fr: 'Listes / Statistiques',
              it: 'Liste / Statistiche'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Berichte',
              fr: 'Berichte',
              it: 'Berichte'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Administration',
          fr: 'Administration',
          it: 'Amministrazione'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Monatsabschluss',
              fr: 'Clôture mensuelle',
              it: 'Dichiarazione mensile'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Kriterien',
              fr: 'Kriterien',
              it: 'Kriterien'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Milchverwertung',
      fr: 'Utilisation du lait',
      it: 'Utilizzo del latte'
    },
    level: 1,
    children: [
      {
        route: 'dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Produkte',
          fr: 'Produits',
          it: 'Prodotti'
        },
        level: 2,
        children: [
          {
            route: '/products/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Milchverwertung (TSM1)',
          fr: 'Utilisation du lait (TSM1)',
          it: 'Utilizzo del latte (TSM1)'
        },
        level: 2,
        children: [
          {
            route: '/processingFormTemplates/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Gesuchsvorlagen',
              fr: 'Modèles de demandes',
              it: 'Modelli di richieste'
            },
            level: 3,
            children: []
          },
          {
            route: '/processingForms/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Gesuche',
              fr: 'Requêtes',
              it: 'Richieste'
            },
            level: 3,
            children: []
          }
        ]
      },

      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Segmentierung (BOM)',
          fr: 'Segmentation (IP)',
          it: 'Segmentazione (IP)'
        },
        level: 2,
        children: [
          {
            route: '/segmentationRelations/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Geschäftsbeziehungen',
              fr: "Relations d'affaires",
              it: 'Relazioni commerciali'
            },
            level: 3,
            children: []
          },
          {
            route: '/segmentationForms/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'BOM Mengenmeldungen',
              fr: 'Quantités IP Lait',
              it: 'Quantità IP Latte'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Zulagen & Inkasso',
          fr: 'Suppléments & Encaissement',
          it: 'Indennità & Incasso'
        },
        level: 2,
        children: [
          {
            route: '/subsidyCreditors/processing/editDialog',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Bankverbindungsangaben',
              fr: 'Données bancaires',
              it: 'Dati bancari'
            },
            level: 3,
            children: []
          },
          {
            route: '/subsidyInvoices/processing',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Abrechnungen',
              fr: 'Décomptes',
              it: 'Conteggi'
            },
            level: 3,
            children: []
          },
          {
            //"route": "/subsidyConfigs?subsidyType=processing",
            route: '/subsidyConfigs',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Verwaltung',
              fr: 'Administration',
              it: 'Amministrazione'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Milchstatistik',
          fr: 'Statistiques laitières',
          it: 'Statistiche del latte'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Monatsstatistik',
              fr: 'Statistiques mensuelles',
              it: 'Statistiche mensili'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertungen',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/processingForms/reports/overview',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'TSM1 Gesuchsübersicht',
              fr: 'TSM1 Aperçu des requêtes',
              it: 'TSM1 Panoramica delle richieste'
            },
            level: 3,
            children: []
          },
          {
            route: '/processingForms/reports/multi',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'TSM1 Gesuchspositionen',
              fr: 'TSM1 Positions des requêtes',
              it: 'TSM1 Posizioni delle richieste'
            },
            level: 3,
            children: []
          },
          {
            route: '/processingForms/reports/plausibility',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'TSM1 Plausibilisierung',
              fr: 'TSM1 Plausibilisierung',
              it: 'TSM1 Plausibilizzazione'
            },
            level: 3,
            children: []
          },
          {
            route: '/segmentationForms/reports/overview',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'BOM Meldungsübersicht',
              fr: 'IP Lait - Aperçu des requêtes',
              it: 'IP Latte - Panoramica delle richieste'
            },
            level: 3,
            children: []
          },
          {
            route: '/segmentationForms/reports/differences',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'BOM Meldungen Melder',
              fr: 'IP Lait - Messages',
              it: 'IP Latte - Messaggi'
            },
            level: 3,
            children: []
          },
          {
            route: '/segmentationForms/reports/inputOutput',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'BOM Ein-/Ausgang',
              fr: 'IP Lait - Entrée / Sortie',
              it: 'IP Latte - Ingresso / Uscita'
            },
            level: 3,
            children: []
          },
          {
            route: '/products/reports/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Diverses',
              fr: 'Divers',
              it: 'Diverso'
            },
            level: 3,
            children: []
          }
        ]
      }
      /*{
                                                                          route: '/dummy',
                                                                          visibility: false,
                                                                          selectedPath: false,
                                                                          label: {
                                                                            de: 'Administration',
                                                                            fr: 'Administration',
                                                                            it: 'Amministrazione'
                                                                          },
                                                                          level: 2,
                                                                          children: [
                                                                            {
                                                                              route: '/productGroups',
                                                                              visibility: false,
                                                                              selectedPath: false,
                                                                              label: {
                                                                                de: 'Produktgruppen',
                                                                                fr: 'Groupes de produits',
                                                                                it: 'Gruppi di prodotti'
                                                                              },
                                                                              level: 3,
                                                                              children: []
                                                                            }
                                                                          ]
                                                                        }*/
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Zusatzmodule',
      fr: 'Modules additionnels',
      it: 'Moduli addizionali'
    },
    level: 1,
    children: [
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Mitgliederverwaltung',
          fr: 'Zone des membres',
          it: 'Zona membri'
        },
        level: 2,
        children: [
          {
            route: '/contacts/searchMasterData',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Suche Stammdaten',
              fr: 'Recherche / Données de base',
              it: 'Cerca / Data Principali'
            },
            level: 3,
            children: []
          },
          {
            route: '/contacts/searchIndependent',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Suche freie Mitglieder',
              fr: 'Recherche / membres gratuits',
              it: 'Cerca / membri liberi'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Mengenmanagement',
          fr: 'Gestion quantités',
          it: 'Gestione quantitativi'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suche / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Lieferrechte',
              fr: 'Doits de livraison',
              it: 'Diritti di fornitura'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Vertragsmengen',
              fr: 'Vertragsmengen',
              it: 'Vertragsmengen'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Bewegungen',
              fr: 'Mutations',
              it: 'Mutazione'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Optionen',
              fr: 'Options',
              it: 'Opzioni'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Einlieferungen (Tagesmengen)',
          fr: 'Einlieferungen (Tagesmengen)',
          it: 'Einlieferungen (Tagesmengen)'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Mengenvermittlung',
          fr: 'Mengenvermittlung',
          it: 'Mengenvermittlung'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suche / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Übertragung Vertragsmengen',
              fr: 'Übertragung Vertragsmengen',
              it: 'Übertragung Vertragsmengen'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Optionen',
              fr: 'Options',
              it: 'Opzioni'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Inkasso',
          fr: 'Encaissement',
          it: 'Incasso'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Fakturierung',
          fr: 'Facturation',
          it: 'Fatturazione'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suche / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Rechnungslauf (Suche / Neu)',
              fr: 'Rechnungslauf (Suche / Neu)',
              it: 'Rechnungslauf (Suche / Neu)'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Optionen',
              fr: 'Options',
              it: 'Opzioni'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'mooh',
          fr: 'mooh',
          it: 'mooh'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Hoftafeln',
              fr: 'Hoftafeln',
              it: 'Hoftafeln'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'casei',
          fr: 'casei',
          it: 'casei'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Beratung',
              fr: 'Beratung',
              it: 'Beratung'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Beratungen',
          fr: 'Beratungen',
          it: 'Beratungen'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suche / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertungen',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Listen / Statistik',
              fr: 'Listes / Statistiques',
              it: 'Liste / Statistiche'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Administration',
          fr: 'Administration',
          it: 'Amministrazione'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Zuzuordnende Mengen',
              fr: 'Quantités à attribuer',
              it: 'Quantitativi da assegnare'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Regeln Mengenzuordnung',
              fr: 'Regeln Mengenzuordnung',
              it: 'Regeln Mengenzuordnung'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Mengenzuordnung',
              fr: 'Attribution quantités',
              it: 'Imputazione quantitativi'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Saldi Lieferrechte',
              fr: 'Solde droits de livraisons',
              it: 'Saldo diritti di fornitura'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Milchgeldabrechnung',
      fr: "Décompte de l'argent du lait",
      it: 'Conteggio dei soldi del latte'
    },
    level: 1,
    children: [
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Konfiguration',
          fr: 'Konfiguration',
          it: 'Konfiguration'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Abrechnungskomp.',
              fr: 'Abrechnungskomp.',
              it: 'Abrechnungskomp.'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Einzelverrechnung',
              fr: 'Einzelverrechnung',
              it: 'Einzelverrechnung'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Berechnungsformeln',
              fr: 'Berechnungsformeln',
              it: 'Berechnungsformeln'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Wertetabellen',
              fr: 'Wertetabellen',
              it: 'Wertetabellen'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Milchklassen',
              fr: 'Milchklassen',
              it: 'Milchklassen'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Milchmengen',
          fr: 'Milchmengen',
          it: 'Milchmengen'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Erfassen',
              fr: 'Erfassen',
              it: 'Erfassen'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Importieren',
              fr: 'Importieren',
              it: 'Importieren'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'MPD exportieren',
              fr: 'MPD exportieren',
              it: 'MPD exportieren'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Klassifizierung',
              fr: 'Klassifizierung',
              it: 'Klassifizierung'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen',
              fr: 'Recherche',
              it: 'Cerca'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Zusatzwerte',
          fr: 'Zusatzwerte',
          it: 'Zusatzwerte'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Erfassen',
              fr: 'Erfassen',
              it: 'Erfassen'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Private QK-Daten einlesen',
              fr: 'Private QK-Daten einlesen',
              it: 'Private QK-Daten einlesen'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Übrige Laboranalysen',
              fr: 'Übrige Laboranalysen',
              it: 'Übrige Laboranalysen'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Abrechnungen',
          fr: 'Décomptes',
          it: 'Conteggi'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Abrechnungen erzeugen',
              fr: 'Abrechnungen erzeugen',
              it: 'Abrechnungen erzeugen'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Anzeigen / drucken',
              fr: 'Anzeigen / drucken',
              it: 'Anzeigen / drucken'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Archiv',
              fr: 'Archiv',
              it: 'Archiv'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertungen',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Listen / Statistik',
              fr: 'Listes / Statistiques',
              it: 'Liste / Statistiche'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Administration',
          fr: 'Administration',
          it: 'Amministrazione'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Bankangaben Milchkäufer',
              fr: 'Bankangaben Milchkäufer',
              it: 'Bankangaben Milchkäufer'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Einstellungen Ausdruck',
              fr: 'Einstellungen Ausdruck',
              it: 'Einstellungen Ausdruck'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Einstellungen E-Mail',
              fr: 'Einstellungen E-Mail',
              it: 'Einstellungen E-Mail'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Dokumente',
      fr: 'Documents',
      it: 'Documenti'
    },
    level: 1,
    children: [
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Dokumente',
          fr: 'Documents',
          it: 'Documenti'
        },
        level: 2,
        children: [
          {
            route: '/documents/inbox/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suche / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          },
          {
            route: '/messages/inbox/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Nachrichten Eingang',
              fr: 'Messages entrants',
              it: 'Messaggi in arrivo'
            },
            level: 3,
            children: []
          },
          {
            route: '/messages/sent/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Nachrichten Ausgang',
              fr: 'Messages sortants',
              it: 'Messaggi in uscita'
            },
            level: 3,
            children: []
          },
          {
            route: '/subscriptions/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Benachrichtigungen',
              fr: 'Notifications',
              it: 'Notifiche'
            },
            level: 3,
            children: []
          },
          {
            route: '/messageConfigs/read',
            visibility: false,
            visibilityBeta: true,
            selectedPath: false,
            label: {
              de: 'Versand-Einstellungen',
              fr: "Paramètres d'expédition",
              it: 'Impostazioni di spedizione'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Versand',
          fr: 'Envoi',
          it: 'Invio'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suche / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'SMS-Subscriptions',
              fr: 'SMS-Subscriptions',
              it: 'SMS-Subscriptions'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/dummy',
    visibility: false,
    selectedPath: false,
    label: {
      de: 'Administration',
      fr: 'Administration',
      it: 'Amministrazione'
    },
    level: 1,
    children: [
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Benutzer',
          fr: 'Utilisateurs',
          it: 'Utenti'
        },
        level: 2,
        children: [
          {
            route: '/applicationUsers/read',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          },
          {
            route: '/profile',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Profil',
              fr: 'Profil',
              it: 'Profilo'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Verlauf',
          fr: 'Historique',
          it: 'Cronologia'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'History',
              fr: 'Historique',
              it: 'Cronologia'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Protokolle',
              fr: 'Protocoles',
              it: 'Protocolli'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Kommunikation',
              fr: 'Communication',
              it: 'Comunicazione'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Auswertungen',
          fr: 'Évaluations',
          it: 'Valutazioni'
        },
        level: 2,
        children: [
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Queries',
              fr: 'Queries',
              it: 'Queries'
            },
            level: 3,
            children: []
          },
          {
            route: '/dummy',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Zugriffe (google analytics)',
              fr: 'Accès (google analytics)',
              it: 'Accessi (google analytics)'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Texte',
          fr: 'Textes',
          it: 'Testi'
        },
        level: 2,
        children: [
          {
            route: '/translations',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen / Ändern',
              fr: 'Recherche / Modifier',
              it: 'Cerca / Modifica'
            },
            level: 3,
            children: []
          },
          {
            route: '/toasts',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Toasts',
              fr: 'Toasts',
              it: 'Toasts'
            },
            level: 3,
            children: []
          }
        ]
      },
      {
        route: '/dummy',
        visibility: false,
        selectedPath: false,
        label: {
          de: 'Pendenz',
          fr: 'Problème',
          it: 'Problemi'
        },
        level: 2,
        children: [
          {
            route: '/issues/search',
            visibility: false,
            selectedPath: false,
            label: {
              de: 'Suchen / Neu',
              fr: 'Recherche / Nouveau',
              it: 'Cerca / Nuovo'
            },
            level: 3,
            children: []
          }
        ]
      }
    ]
  }
]
