//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import MilkQualityDeliveryStopStatusesStore from '@/store/enums/MilkQualityDeliveryStopStatusesStore'

computed: {
    ...mapGetters('MilkQualityDeliveryStopStatusesStore', ['milkQualityDeliveryStopStatuses'])
    },
methods:{
    ...mapActions('MilkQualityDeliveryStopStatusesStore', ['fetchMilkQualityDeliveryStopStatuses']),
}
mounted() {   
  this.fetchMilkQualityDeliveryStopStatuses()

*/

@Module({ namespaced: true })
export default class MilkQualityDeliveryStopStatusesStore extends VuexModule {
  MilkQualityDeliveryStopStatuses: any[] = []

  get milkQualityDeliveryStopStatuses() {
    return this.MilkQualityDeliveryStopStatuses
  }

  @Mutation
  setMilkQualityDeliveryStopStatuses(MilkQualityDeliveryStopStatuses: any) {
    this.MilkQualityDeliveryStopStatuses = MilkQualityDeliveryStopStatuses
  }

  @Action({ commit: 'setMilkQualityDeliveryStopStatuses' })
  async fetchMilkQualityDeliveryStopStatuses() {
    if (this.MilkQualityDeliveryStopStatuses.length == 0) {
      try {
        const result = await axios.get(apiURL + '/deliveryStop')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.MilkQualityDeliveryStopStatuses
    }
  }
}
