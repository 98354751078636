<template>
  <v-card class="mx-auto">
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-list dense>
      <template v-for="(item, index) in issues">
        <template v-if="privileges.has({ path: '/issues', permission: 'write' })">
          <v-list-item :key="item.id" @click="navigateToIssueDetail(item.id)">
            <v-list-item-avatar>
              <v-chip pill :color="colors[item.type]" style="width: 2rem"> </v-chip>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item['subject' + $route.params.upLang] }}</v-list-item-title>
            </v-list-item-content>
            <v-divider v-if="index < issues - 1" :key="index"></v-divider>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import { protocol } from '@/main'
import { vueApp } from '@/main'
import { showError } from '@/services/axios'
import { apiURL } from '@/main'

export default {
  name: 'issuePreview',
  data() {
    return {
      issues: [],
      loading: false,
      protocol: protocol,
      vueApp: vueApp,
      colors: {
        GENERAL: '#9b9b9b',
        WARNING: 'red'
      },
      icons: {
        GENERAL: 'mdi-checkbox-marked-circle',
        WARNING: 'mdi-alert'
      }
    }
  },
  computed: {
    getLegalEntityId() {
      return this.$store.getters['getSelectedPersonId']
    }
  },
  watch: {
    getLegalEntityId: {
      handler() {
        if (this.getLegalEntityId != 0) {
          this.loadInbox()
        }
      }
    }
  },
  methods: {
    async loadInbox() {
      this.loading = true
      try {
        const result = await this.axios.get(apiURL + '/issues/toDoForCurrentUser/')
        this.issues = result.data
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    navigateToIssueDetail(id) {
      this.$router.push({ name: 'issues_edit', params: { id } }).catch((e) => {
        /* duplicated route */
      })
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.getLegalEntityId != 0) {
        await this.loadInbox()
      }
    })
  }
}
</script>
