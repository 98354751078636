//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
computed: {
    ...mapGetters('Cantons', ['cantons'])
    },
methods:{
    ...mapActions('Cantons', ['fetchCantons']),
}
mounted() {   
  this.fetchCantons()
*/

@Module({ namespaced: true })
export default class CantonsStore extends VuexModule {
  Cantons: any[] = []
  CantonsCH: any[] = []
  CantonsIncVirtual: any[] = []

  get cantons() {
    return this.Cantons
  }

  get cantonsCH() {
    return this.CantonsCH
  }

  get cantonsIncVirtual() {
    return this.CantonsIncVirtual
  }

  @Mutation
  setCantons(Cantons: any) {
    this.Cantons = Cantons
  }

  @Mutation
  setCantonsCH(CantonsCH: any) {
    this.CantonsCH = CantonsCH
  }

  @Mutation
  setCantonsIncVirtual(CantonsIncVirtual: any) {
    this.CantonsIncVirtual = CantonsIncVirtual
  }

  @Action({ commit: 'setCantons' })
  async fetchCantons() {
    if (this.Cantons.length === 0) {
      try {
        const result = await axios.get(apiURL + '/cantons')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.Cantons
    }
  }

  @Action({ commit: 'setCantonsCH' })
  async fetchCantonsCH() {
    if (this.CantonsCH.length === 0) {
      try {
        const result = await axios.get(apiURL + '/cantons/CH')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.CantonsCH
    }
  }

  @Action({ commit: 'setCantonsIncVirtual' })
  async fetchCantonsIncVirtual() {
    if (this.CantonsIncVirtual.length === 0) {
      try {
        const result = await axios.get(apiURL + '/cantons?includeVirtual=true')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.CantonsIncVirtual
    }
  }
}
