import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchLocalUnitStore extends VuexModule {
  LocalUnit_berNo: number | null = null
  LocalUnit_name: string | null = null
  LocalUnit_address: string | null = null
  LocalUnit_zip: number | null = null
  LocalUnit_locality: string | null = null
  LocalUnit_egid: string | null = null
  useTerms = true
  items: TermItemDefinition[] = [
    { key: 'LocalUnit_berNo', operation: '~' },
    { key: 'LocalUnit_name', operation: '~' },
    { key: 'LocalUnit_address', operation: '~' },
    { key: 'LocalUnit_zip', operation: ':' },
    { key: 'LocalUnit_locality', operation: '~' },
    { key: 'LocalUnit_egid', operation: ':' }
  ]
}
