import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchSegmentationGroupingStore extends VuexModule {
  groupBySegmentationType: boolean = true
  groupByFeeding: boolean = true
  groupByDate: boolean = true
  groupByDairy: boolean = true

  items: TermItemDefinition[] = [
    { key: 'groupBySegmentationType', operation: ':' },
    { key: 'groupByFeeding', operation: ':' },
    { key: 'groupByDate', operation: ':' },
    { key: 'groupByDairy', operation: ':' }
  ]
}
