//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import CountriesStore from '@/store/enums/CountriesStore'

computed: {
    ...mapGetters('CountriesStore', ['countries'])
    },
methods:{
    ...mapActions('CountriesStore', ['fetchCountries']),
}
mounted() {   
  this.fetchCountries()

*/

@Module({ namespaced: true })
export default class CountriesStore extends VuexModule {
  Countries: any[] = []

  get countries() {
    return this.Countries
  }

  @Mutation
  setCountries(Countries: any) {
    this.Countries = Countries
  }

  @Action({ commit: 'setCountries' })
  async fetchCountries() {
    if (this.Countries.length == 0) {
      try {
        const result = await axios.get(apiURL + '/countries')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.Countries
    }
  }
}
