//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import FarmAccessStatusesStore from '@/store/enums/FarmAccessStatusesStore'

computed: {
    ...mapGetters('FarmAccessStatusesStore', ['farmAccessStatuses'])
    },
methods:{
    ...mapActions('FarmAccessStatusesStore', ['fetchFarmAccessStatuses']),
}
mounted() {   
  this.fetchFarmAccessStatuses()

*/

@Module({ namespaced: true })
export default class FarmAccessStatusesStore extends VuexModule {
  FarmAccessStatuses: any[] = []
  LimitedFarmAccessStatuses: any[] = []

  get farmAccessStatuses() {
    return this.FarmAccessStatuses
  }

  get limitedFarmAccessStatuses() {
    return this.LimitedFarmAccessStatuses
  }

  @Mutation
  setFarmAccessStatuses(FarmAccessStatuses: any) {
    this.FarmAccessStatuses = FarmAccessStatuses
  }

  @Mutation
  setLimitedFarmAccessStatuses(LimitedFarmAccessStatuses: any) {
    this.LimitedFarmAccessStatuses = LimitedFarmAccessStatuses
  }

  @Action({ commit: 'setFarmAccessStatuses' })
  async fetchFarmAccessStatuses() {
    if (this.FarmAccessStatuses.length == 0) {
      try {
        const result = await axios.get(apiURL + '/farmAccessStatuses')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.FarmAccessStatuses
    }
  }

  @Action({ commit: 'setLimitedFarmAccessStatuses' })
  async fetchLimitedFarmAccessStatuses() {
    if (this.LimitedFarmAccessStatuses.length == 0) {
      try {
        const result = await axios.get(apiURL + '/farmAccessStatuses')
        return await result.data.splice(0, result.data.length - 2)
      } catch (e) {
        showError(e)
      }
    } else {
      return this.LimitedFarmAccessStatuses
    }
  }
}
