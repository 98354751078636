import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import axios from '@/services/axios'
import { apiURL } from '@/main'
import { showError } from '@/services/axios'

/*

import DocumentTypeGroupsStore from '@/store/enums/DocumentTypeGroupsStore'

computed: {
    ...mapGetters('DocumentTypeGroupsStore', ['documentTypeGroups'])
    },
methods:{
    ...mapActions('DocumentTypeGroupsStore', ['fetchDocumentTypeGroups']),
}
mounted() {
  this.fetchDocumentTypeGroups()

*/

const documentTypeGroups = [
  {
    nameDe: 'Zulagen',
    name: 'SUBSIDIES',
    nameFr: 'Suppl. lait commercialisé',
    nameIt: 'Suppl. latte commercializzato',
    id: 5
  },
  {
    nameDe: 'Downloads',
    name: 'DOWNLOADS',
    nameFr: 'Downloads',
    nameIt: 'Downloads',
    id: 7
  }
]

@Module({ namespaced: true })
export default class DocumentTypeGroupsStore extends VuexModule {
  DocumentTypeGroups: any[] = []

  get documentTypeGroups() {
    return this.DocumentTypeGroups
  }

  @Mutation
  setDocumentTypeGroups(DocumentTypeGroups: any) {
    this.DocumentTypeGroups = DocumentTypeGroups
  }

  @Action({ commit: 'setDocumentTypeGroups' })
  async fetchDocumentTypeGroups() {
    if (this.DocumentTypeGroups.length == 0) {
      try {
        // todo atm we show only 'Zulagen', enable 'get' if you need all groups
        // const result = await axios.get(apiURL + '/documentTypeGroups')
        // return result.data
        return documentTypeGroups
      } catch (e) {
        showError(e)
      }
    } else {
      return this.DocumentTypeGroups
    }
  }
}
