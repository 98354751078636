//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
import LabelBundleStore from '@/store/enums/LabelBundleStore'

computed: {
    ...mapGetters('LabelBundleStore', ['labelBundles'])
    },
methods:{
    ...mapActions('LabelBundleStore', ['fetchLabelBundles']),
}
mounted() {   
  this.fetchLabelBundles()

*/

@Module({ namespaced: true })
export default class LabelBundleStore extends VuexModule {
  LabelBundles: any[] = []

  get labelBundles() {
    return this.LabelBundles
  }

  @Mutation
  setLabelBundles(LabelBundles: any) {
    this.LabelBundles = LabelBundles
  }

  @Action({ commit: 'setLabelBundles' })
  async fetchLabelBundles() {
    if (this.LabelBundles.length == 0) {
      try {
        const result = await axios.get(apiURL + '/labelBundles')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.LabelBundles
    }
  }
}
