import { TermItemDefinition } from '@/services/term'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('SearchProcessingRowStore/resetFullItems'
store.commit('SearchProcessingRowStore/addItem')


computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchProcessingRowStore extends VuexModule {
  codes = ''
  fullItems: any[] = []

  @Mutation
  resetFullItems() {
    this.fullItems = []
  }

  @Mutation
  addItem(item: any) {
    this.fullItems.push(item)
  }

  @Mutation
  resetCodes() {
    this.codes = ''
  }

  @Mutation
  addCode(code: string) {
    this.codes = code
  }
}
