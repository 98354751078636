import { apiURL, i18n, vueApp } from '../main'
import axios from '../services/axios'
import { NavigationGuardNext, Route } from 'vue-router'
import { capitalize, nameLocale } from '../utils/various'
import { showError } from './axios'
import store from '@/store'

export const Languages: string[] = ['de', 'fr', 'it']
export const defaultLanguage = Languages.includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : Languages[0]

class Language {
  private static _instance: Language
  public loading = false

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  private selectedLanguage = 'jp' // erst der switch auf de lädt sprachen ..
  private upperCaseLanguage = 'Jp'

  // i only push the route.. the language is setted by  the route guard  see routerBeforeEach below
  pushRoute(newlanguage: string) {
    if (Languages.includes(newlanguage)) {
      vueApp.$router
        .push({
          name: vueApp.$route.name ?? 'Home',
          query: vueApp.$route.query ?? {},
          params: { ...(vueApp.$route.params ?? {}), ...{ lang: newlanguage } }
        })
        .catch(() => {
          //router Guard throws error if no catch is here
        })
    }
  }

  /* i'm registered in Router beforeEach */
  async routeIntercepter(to: Route, from: Route, next: NavigationGuardNext): Promise<any> {
    let nextLang = defaultLanguage
    if (Languages.includes(to.params.lang)) {
      nextLang = to.params.lang
    } else {
      next({ name: to.name ?? '', params: { lang: defaultLanguage }, query: to.query, hash: to.hash })
      //next({ name: to.name ?? '', params: { lang: defaultLanguage } });
    }
    if (nextLang != Language._instance.selectedLanguage) {
      axios.defaults.headers.common['Accept-Language'] = nextLang
      Language._instance.selectedLanguage = nextLang
      Language._instance.upperCaseLanguage = nextLang[0].toUpperCase() + nextLang.slice(1)
      await Language._instance.loadTranslations(nextLang, false)
      to.params.lang = nextLang
      next({ name: to.name ?? '', params: to.params, query: to.query, hash: to.hash })
    } else {
      to.params.isoLangKey = nameLocale(to.params.lang) + '-ch' //de-ch
      to.params.upLang = capitalize(to.params.lang) //De
      to.params.langKey = nameLocale(to.params.lang) //nameDe
      to.params.upLangKey = capitalize(nameLocale(to.params.lang)) //NameDe
      next()
    }
  }

  //forced reload after frontend ui, language change
  async loadTranslations(lang: string, forced: boolean) {
    //let storedTranslation = store.getters['TranslationsStore/getTranslation' + capitalize(lang)]
    let storedTranslation = store.state.TranslationsStore[lang]
    if (!forced && typeof storedTranslation?.cancel == 'string') {
      try {
        console.log('loadTranslations translation detected')
        i18n.setLocaleMessage(lang, storedTranslation)
        i18n.locale = lang
      } catch (e) {
        console.log('loadTranslations i18n settings', e)
      }
      try {
        if (typeof vueApp?.$vuetify?.lang?.current == 'string') {
          vueApp.$vuetify.lang.current = lang
        }
      } catch (e) {
        console.log('loadTranslations vuetfiy lang settings', e)
      }
      return storedTranslation
    }

    try {
      Language._instance.loading = true
      const result: any = await axios.get(apiURL + '/translations/language/' + lang, {
        params: { language: lang }
      })
      i18n.setLocaleMessage(lang, await result.data)
      i18n.locale = lang
      vueApp.$vuetify.lang.current = lang
      vueApp.$store.commit('TranslationsStore/setTranslation' + capitalize(lang), await result.data)
      Language._instance.loading = false
      return await result.data
    } catch (e) {
      Language._instance.loading = false
      console.log('loadTranslations', e)
      showError(e)
    }
  }

  showLanguageKeys() {
    const keys = Object.keys(i18n.messages[i18n.locale])
    keys.forEach((key) => {
      i18n.setLocaleMessage(i18n.locale, { key: key })
    })
  }

  getTranslatedNameProperty(object: any) {
    if (!object.nameDe) throw new Error('Invalid object supplied as argument, no nameDe property is present')

    return object['name'] + this.selectedLanguage[0].toUpperCase() + this.selectedLanguage.slice(1)
  }
}

export const language = Language.Instance
