import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'
import moment from 'moment'

/*
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
//We need a separate store for searchProductionOverview because of different default values
@Module({ namespaced: true })
export default class SearchProductionOverviewStore extends VuexModule {
  status: string | null = null
  animalTypeId: number | null = null
  period: string | null = moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD')
  loadPreviousMonths: string | null = null
  quantityFrom: number | null = null
  quantityTo: number | null = null
  createdFrom: string | null = null
  createdUntil: string | null = null
  countRemarks: number | null = null
  countFiles: number | null = null

  items: TermItemDefinition[] = [
    { key: 'status', operation: ':', itemType: 'string' },
    { key: 'animalTypeId', operation: ':', itemType: 'number' },
    { key: 'period', operation: ':', itemType: 'string' },
    { key: 'loadPreviousMonths', operation: ':', itemType: 'string' },
    { key: 'quantityFrom', operation: ':' },
    { key: 'quantityTo', operation: ':' },
    { key: 'createdFrom', operation: ':' },
    { key: 'createdUntil', operation: ':' },
    { key: 'countRemarks', operation: ':' },
    { key: 'countFiles', operation: ':' }
  ]
}
