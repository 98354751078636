<template>
  <div class="d-print-none">
    <v-overlay v-if="forceDomainSelection" :opacity="0.58"></v-overlay>
    <v-container id="topnav_person" class="container-xl container keepInside">
      <div class="header-bar-left" id="logo">
        <img src="../assets/images/bdlaitch_rgb.svg" alt="dbmilch" class="logo-size" height="48" width="200" v-if="languageKey === 'fr' || languageKey === 'it'" />
        <img src="../assets/images/dbmilchch_rgb.svg" alt="dbmilch" class="logo-size" height="48" width="200" v-else />
      </div>
      <div class="header-bar-right margin-left center-vertically" id="profile">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" id="profileMenu">
              <v-icon dbmpassivgrey>mdi-dots-horizontal</v-icon>
            </span>
          </template>
          <v-list>
            <v-subheader v-if="isAuthenticated">{{ userName }}</v-subheader>
            <v-subheader v-else>...</v-subheader>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title" v-html="$t('system_menu_user_account')"></v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAuthenticated" :to="{ name: 'profile', params: { lang: $route.params.lang, tab: 'user' } }">
              <v-subheader id="menuProfile" v-html="$t('system_menu_profile')"></v-subheader>
            </v-list-item>
            <v-list-item link>
              <v-subheader id="menuLogout" @click.prevent="signOut" v-if="isAuthenticated">
                <span v-html="$t('system_menu_logout')" />
              </v-subheader>
              <v-subheader id="menuLogin" @click.prevent="login()" v-else v-html="$t('system_menu_login')"></v-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title" v-html="$t('system_menu_service')"></v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-subheader id="menuHelpContact" @click.prevent="gotoExternalUrl($t('url_help_contact'))">
                <span v-html="$t('system_menu_help_contact')" />
              </v-subheader>
            </v-list-item>
            <v-list-item link>
              <v-subheader id="menuInstructions" @click.prevent="gotoExternalUrl($t('url_instructions'))">
                <span v-html="$t('system_menu_instructions')" />
              </v-subheader>
            </v-list-item>
            <v-list-item>
              <!-- webpack will inject a timestamp check <html lang="en" data-build-timestamp-utc="<%= new Date().toISOString() %>"> in index.html  -->
              <v-subheader id="version">Build {{ builded }}</v-subheader>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="header-bar-right margin-left mt-1" id="language">
        <LanguageSelector />
      </div>
      <div class="header-bar-right margin-left center-vertically" id="search" v-if="privileges.has({ path: '/masterdata', permission: 'read' })">
        <a href class="link-right" @click.prevent="search">
          <v-icon right>mdi-magnify</v-icon>
          <span v-html="$t('search')"
        /></a>
      </div>
      <div class="header-bar-right margin-left center-vertically" id="logout" v-if="isAuthenticated">
        <a href class="link-right" @click.prevent="signOut" v-html="$t('logout')"></a>
      </div>
      <div class="header-bar-right margin-left center-vertically" id="login" v-else>
        <a href class="link-right" @click.prevent="login" v-html="$t('login')"></a>
      </div>
      <v-dialog persistent v-model="loginBlocker" width="800px">
        <v-card>
          <v-card-title>
            <span v-html="$t('system_pls_login_dialog_title')" />
          </v-card-title>
          <v-card-text>
            <span v-html="$t('system_pls_login_dialog_text')" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click.prevent="login()" v-html="$t('system_pls_login_dialog_action_login')"></v-btn>
            <v-btn color="secondary" :to="{ name: 'home', params: { lang: $route.params.lang } }">
              <span v-html="$t('system_pls_login_dialog_action_home')" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <span v-if="personsError" class="header-bar-right margin-left center-vertically" id="personsError">
        <v-btn icon color="red" @click="reloadLegalentitiesAndPrivileges"><span v-html="$t('unknown_error_pls_reload')" /><v-icon>mdi-cached</v-icon> </v-btn>
      </span>
      <span v-if="personsService.loading" class="header-bar-right margin-left center-vertically" id="domainLoading"><v-progress-circular indeterminate></v-progress-circular> </span>
      <span v-else-if="!multipleDomains || (hasDomainSwitch && !hasUserSwitch)">
        <div class="header-bar-right margin-left center-vertically" id="domSwitch">
          {{ getDomainName ? getDomainName : personsService.getSelectedPersonName() }}
          <span v-if="hasDomainSwitch && !hasUserSwitch" class="link" id="domainSwitch_reset" @click="backToOriginDomain()">
            <v-icon right>mdi-close</v-icon>
          </span>
        </div>
      </span>
      <span v-else>
        <div class="header-bar-right margin-left center-vertically" id="domSwitchMulti">
          <span v-if="forceDomainSelection">
            <v-select class="domain-margin-top over-the-overlay" :items="persons" v-model="domainId" item-value="id" v-open-menu>
              <template v-slot:selection="{ item }">
                {{ getDomainText(item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ getDomainText(item) }}
              </template>
            </v-select>
          </span>
          <span v-else>
            <v-select class="domain-margin-top" :items="persons" v-model="domainId" item-value="id" single-line>
              <template v-slot:selection="{ item }">
                {{ getDomainText(item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ getDomainText(item) }}
              </template>
            </v-select>
          </span>
        </div>
      </span>

      <span>
        <div v-if="hasUserSwitch" class="header-bar-right margin-left center-vertically" id="userSwitch">
          <span class="link" id="userSwitch_reset" @click="backToOriginUser()">{{ personsService.getSelectedApplicationUserName() }}<v-icon right>mdi-close</v-icon> </span>
        </div>
      </span>
    </v-container>

    <div class="navigation-borders">
      <div class="container-xl container pb-0 pt-0">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link class="menu-padding navbar-brand home-color" :to="{ name: 'home', params: { lang: $route.params.lang } }"> HOME </router-link>
          <v-progress-linear v-if="privileges.loading" indeterminate></v-progress-linear>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavLightDropdown" aria-controls="navbarNavLightDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <!-- <span class="navbar-toggler-icon"></span> -->
            <v-icon dbmpassivgrey>mdi-menu</v-icon>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavLightDropdown">
            <ul class="navbar-nav">
              <li v-for="item in navTree" v-bind:key="item.label[languageKey]" :class="item.selectedPath ? 'level0 nav-item dropdown menu-font selectedPath' : 'level0 nav-item dropdown menu-font'" v-show="item.visibility">
                <a class="nav-link menu-padding home-color" href="#" :id="'level0_' + item.label[languageKey]" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <!-- <button> -->
                  {{ upper(item) }}
                  <!-- <i class="fa fa-caret-down"></i>
                </button> -->
                </a>
                <ul class="dropdown-menu dropdown-menu-light" :aria-labelledby="'level0_' + item.label[languageKey]" :id="'Flyout_' + item.label[languageKey]">
                  <div class="container-xl container pb-0 pt-0 left-margin-and-padding-less">
                    <li v-for="(child, indx) in item.children" v-bind:key="'child1_' + indx" v-show="child.visibility" class="menu-column">
                      <a :class="child.selectedPath ? 'dropdown-item custom-dropdown-item-title selectedPath' : 'dropdown-item custom-dropdown-item-title'" href="#" :id="'level1_' + child.label[languageKey]" role="button">{{ child.label[languageKey] }}</a>

                      <ul class="dropdown-entry dropdown-entry-hover" :aria-labelledby="'level1_' + item.label[languageKey]">
                        <li v-for="(child2, indx2) in child.children" v-bind:key="'child2_' + indx2" v-show="child2.visibility">
                          <a v-on:click="gotoLegacyNav(child2.route)" v-if="child2.legacy">{{ child2.label[languageKey] }}</a>
                          <router-link :class="child2.selectedPath ? 'selectedPath' : ''" :to="languagePrefix + child2.route" v-else>
                            {{ child2.label[languageKey] }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import { navTree } from '@/router/navTree'
import LanguageSelector from '../components/languageSelector.vue'
import { privileges } from '@/services/privileges'
import { personsService } from '@/services/persons'
import { vueApp, apiURL } from '@/main'
import { authService } from '@/services/auth.service'
import axios from '@/services/axios'
import store from '@/store'

export default {
  name: 'navigation',
  components: {
    LanguageSelector: LanguageSelector
  },
  data() {
    return {
      navTree: navTree,
      routeInfo: { path: 'nope' },
      showCollapsedMenu: false,
      selectedMegaMenu: '',
      personsService: personsService,
      personsError: false
    }
  },
  computed: {
    isAuthenticated() {
      return typeof authService == 'object'
    },
    builded() {
      return this.$moment(document.documentElement.dataset.buildTimestampUtc).format('DD.MM.YYYY HH:mm')
    },
    loginBlocker() {
      return !this.isAuthenticated
    },
    languageKey() {
      try {
        return this.$route.params.lang
      } catch (e) {
        return 'de'
      }
    },
    languagePrefix() {
      return '/' + this.$route.params.lang
    },
    userName() {
      if (authService.loadedUser?.profile?.family_name) {
        return authService.loadedUser.profile.family_name
      }
      if (authService.loadedUser?.profile?.given_name) {
        return authService.loadedUser.profile.given_name
      }
      if (authService.loadedUser?.profile?.email) {
        return authService.loadedUser.profile.email
      }
      return ' - '
    },
    persons() {
      return this?.$store?.state?.PersonsStore?.persons
    },
    forceDomainSelection() {
      if (this.$store.state.PersonsStore.persons.length <= 1) return false // no selection required when only one option
      if (this.$store.state.PersonsStore.applicationUser == null) return false // no selection required if no last login record
      if (this.$store.state.PersonsStore.applicationUser?.lastLogin == null || this.$store.state.PersonsStore.applicationUser?.lastSelectedLegalEntityId == null) return true // no selection required if no last login record
      return this.$store.state.PersonsStore.applicationUser.lastLogin.substring(0, 10).localeCompare(new Date().toISOString().substring(0, 10)) != 0 // selection required if last login >1day ago
    },
    domainId: {
      get() {
        // return this.$store.state.PersonsStore.selectedPersonId
        return this.forceDomainSelection ? null : this.$store.state.PersonsStore.selectedPersonId
      },
      async set(newValue) {
        if (this.forceDomainSelection) {
          this.$store.state.PersonsStore.applicationUser.lastLogin = this.$store.state.PersonsStore.applicationUser.thisLogin //otherwise forcedomain will pop up
        }
        if (this.hasUserSwitch) {
          await personsService.switchDomainAsSelectedUser(newValue)
        }
        await personsService.setDomain(newValue)
        // update lastLogin for first time users, disabling the overlay by making lastLogin and lastLegalEntityId available
        if (this.$store.state.PersonsStore.applicationUser.lastSelectedLegalEntityId == null) {
          this.$store.state.PersonsStore.applicationUser.lastLogin = this.$store.state.PersonsStore.applicationUser.thisLogin
          this.updateLastLogin()
        }
      }
    },
    multipleDomains() {
      return this.persons.length > 1
    },
    hasDomainSwitch() {
      return this.$store.getters['hasDomainSwitch']
    },
    getDomainName() {
      return this.$store.getters['getDomainName']
    },
    hasUserSwitch() {
      return this.$store.getters['getSelectedApplicationUserId'] > 0
    }
  },
  methods: {
    async updateLastLogin() {
      try {
        // update db
        const requestedLastLogin = await axios.patch(apiURL + '/applicationUsers/updateLastLogin')
        // update store
        this.$store.state.PersonsStore.applicationUser.lastLogin = requestedLastLogin.data.lastLogin
        this.$store.state.PersonsStore.applicationUser.lastSelectedLegalEntityId = requestedLastLogin.data.lastSelectedLegalEntity.id
      } catch (e) {
        console.error(e)
      }
    },
    async reloadLegalentitiesAndPrivileges() {
      this.personsError = false
      try {
        await personsService.loadLegalEntity()
      } catch {
        this.personsError = true
      }
    },
    async login() {
      try {
        await authService.login()
      } catch {
        vueApp.$toasted.global.error({ message: i18n.t('idp_unavailable') })
      }
    },

    search(e) {
      let routeName = 'masterdata_search'
      if (privileges.has({ path: '/masterdata/find', permission: 'read' })) {
        routeName = 'masterdata_find'
      }
      this.$router
        .push({
          name: routeName,
          params: this.$route.params
        })
        .catch((e) => {
          //you need to have a catcher
        })
    },
    cleanup() {
      this.personsService.reset()
      this.$store.reset()
      this.reloadNav()
    },
    signOut() {
      this.cleanup()
      window.setTimeout(() => {
        authService.logout()
      }, 250)
    },
    oidcUserError() {
      this.cleanup()
      window.setTimeout(() => {
        authService
          .login()
          .then(() => {
            console.log('oidcinterceptor igninRedirect success')
          })
          .catch(() => {
            vueApp.$toasted.global.error({ message: i18n.t('idp_unavailable') })
          })
      }, 250)
    },
    upper(item) {
      return item.label[this.languageKey ? this.languageKey : 'de'].toUpperCase()
    },
    gotoExternalUrl(url) {
      window.open(url, '_blank')
    },
    setRouteInfo(routeInfo) {
      this.routeInfo = routeInfo.path
      this.setFocusElements(this.navTree, this.routeInfo && this.routeInfo.length ? this.routeInfo : '#nope')
    },
    setFocusElements(arrayOfRouteElements, focusPath) {
      let anyFocus = false
      arrayOfRouteElements.forEach((element) => {
        if (focusPath.replace(/\/\d+/, '/read').search(element.route) > 0) {
          // replace /{id} with /read
          // TRUE :: ("#/de/processingFormTemplates/read").search("/processingFormTemplates")
          element.selectedPath = true
          anyFocus = true
        } else {
          if (element.children && element.children.length > 0) {
            // check the children
            element.selectedPath = this.setFocusElements(element.children, focusPath)
            anyFocus = anyFocus || element.selectedPath
          } else {
            element.selectedPath = false
          }
        }
      })
      return anyFocus
    },
    setNavigationVisibility(arrayOfRouteElements) {
      let somethingVisible = false
      arrayOfRouteElements.forEach((element) => {
        if (element.children && element.children.length > 0) {
          element.visibility = this.setNavigationVisibility(element.children)
        } else {
          // no children

          // do not display betaFeatures
          if (element.visibilityBeta && element.visibilityBeta === true && !this.$getBeta()) {
            element.visibility = false
            return false
          }
          // legacy link
          element.legacy = element.route.substring(0, 7) === '/legacy'
          if (element.legacy && !this.$legacyEnabled()) {
            element.visibility = false
            return false
          }
          // detect path ending with search/read
          if (element.route.substring(element.route.length - '/search'.length) === '/search') {
            const testpath = element.route.substring(0, element.route.length - '/search'.length)
            element.visibility = privileges.has({
              path: testpath,
              permission: 'search'
            })
          } else if (element.route.substring(element.route.length - '/read'.length) === '/read') {
            const testpath = element.route.substring(0, element.route.length - '/read'.length)
            element.visibility = privileges.hasOneOf([
              {
                path: testpath,
                permission: 'read'
              },
              {
                path: testpath,
                permission: 'search'
              }
            ])
          } else {
            element.visibility = privileges.has({
              path: element.route,
              permission: 'read'
            })
          }
        }
        somethingVisible = somethingVisible || element.visibility
      })
      // already returned true above if any element is true
      return somethingVisible
    },
    reloadNav() {
      this.setNavigationVisibility(this.navTree)
    },
    async backToOriginDomain() {
      const domainId = this.$store.getters['getOriginId']
      await personsService.backToOriginDomain(domainId)
    },
    async backToOriginUser() {
      vueApp.$store.commit('setSelectedApplicationUser', 0)
      await this.backToOriginDomain()
    },

    getDomainText: (item) => ` ${item.name1 ? item.name1 : ''} ${item.name2 ? item.name2 : ''} `,
    gotoLegacyNav(route) {
      this.$gotoLegacy(route.split('/')[2], route.split('/')[3])
    }
  },
  directives: {
    openMenu: {
      inserted(el) {
        el.__vue__.isMenuActive = true // force open the select menu
      }
    }
  },
  watch: {
    $route(to, from) {
      this.setRouteInfo(to)
    }
  },
  mounted() {
    vueApp.$on('reloadNav', this.reloadNav)
    vueApp.$on('privilegesNew', this.reloadNav)
    this.reloadNav()
    if (this.$store.state.PersonsStore.applicationUser?.id) {
      personsService.init(this.$store.state.PersonsStore.applicationUser, this.$store.state.PersonsStore.persons)
    }
    vueApp.$on('oidcUserLoaded', personsService.oidcUserLoaded, vueApp.$store.getters['getPersons'])
    vueApp.$on('oidcUserUnloaded', this.oidcUserError)
    vueApp.$on('oidcSilentRenewError', this.oidcUserError)
    vueApp.$on('backendUnAuthorized', this.oidcUserError)
  },

  destroyed() {
    vueApp.$off('reloadNav')
    vueApp.$off('privilegesNew')
    vueApp.$off('oidcUserLoaded')
    vueApp.$off('oidcUserUnloaded')
    vueApp.$off('oidcSilentRenewError')
    vueApp.$off('backendUnAuthorized')
  }
}
</script>

<style lang="scss">
@import '../styles/colors.scss';

/*Topnav*/
#topnav_person {
  padding-top: 3px;
  padding-bottom: 3px;
}

#topnav_person > div {
  margin-left: 28px;
}

#topnav_person > div:first-child {
  margin-left: 0;
}

.v-list-item .v-subheader {
  padding-left: 0 !important;
}

/*Farben*/
#topnav_person a,
#topnav_person i,
#domSwitch,
#topnav_person .v-select__selections {
  color: $dbm-passiv-grey !important;
}

#topnav_person .theme--light.v-text-field > .v-input__control > .v-input__slot:before,
#topnav_person #input-12 {
  display: none;
}

#topnav_person .v-input__slot {
  background: $dbm-white;
  padding: 0;
}

#topnav_person .v-input__slot {
  background: #fff;
  padding: 0;
}

/*ordnung sprachmenu*/
#topnav_person .v-select__selections {
  display: flex;
}

#topnav_person .v-select__selections {
  order: 1;
}

#topnav_person .v-input__append-inner {
  padding-right: 5px;
}

#topnav_person i.v-icon.notranslate.mdi.mdi-web.theme--light {
  margin-top: 2px !important;
}

/*search*/
#search span {
  margin-left: 5px;
}

.v-icon {
  color: #5f5f5f !important;
}

.link-right {
  float: right;
}

.header-bar-left {
  display: inline-block;
}

.margin-left {
  margin-left: $spacer-md;
}

.center-vertically {
  line-height: $header-height;
}

.theme--light.v-select .v-select__selections {
  color: $dbm-passiv-grey !important;
}

.header-bar-right {
  display: inline;
  float: right;
}

.navigation-borders {
  border-top: 1px solid $dbm-line-grey;
  border-bottom: 1px solid $dbm-primary-blue;
  position: relative;
}

.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: $dbm-primary-grey !important;
}

.menu-font {
  font-size: 0.9rem;
}

.home-color {
  color: $dbm-primary-grey !important;
}

.menu-padding {
  padding: $primary-fs 0 !important;
  margin-right: $primary-fs !important;
}

.menu-padding:hover,
.menu-padding:focus {
  padding-bottom: $primary-fs - 2px !important;
  border-bottom: 2px solid $dbm-primary-blue;
}

.custom-dropdown-item-title {
  font-size: 0.9em;
  border-bottom: 1px solid $dbm-primary-grey !important;
  padding: 1rem 0 0.5em 0 !important;
}

.custom-dropdown-item-title:hover {
  background-color: transparent !important;
  color: $dbm-primary-grey !important;
}

.dropdown-entry {
  list-style-type: none;
  padding: 0 !important;
  margin: 0;
  width: 100%;
  margin-bottom: 0px !important;
}

.dropdown-entry-hover a {
  padding-top: 0.5em;
  font-size: 0.8rem;
  color: $dbm-primary-grey !important;
}

.dropdown-entry-hover a:hover {
  color: $dbm-primary-blue !important;
}

/* Create three equal columns that floats next to each other */
.menu-column {
  float: left;
  width: 25%;
  padding-left: 0;
  padding-right: $grid-gutter-width;
}

.menu-column a {
  float: none;
  display: block;
  text-align: left;
}

/**flyout menu position */
.bootstrap .dropup,
.bootstrap .dropend,
.bootstrap .dropdown,
.bootstrap .dropstart {
  position: initial !important;
}

.bootstrap .navbar {
  position: initial !important;
}

.over-the-overlay {
  z-index: 1000;
}

div.v-menu__content.theme--light.menuable__content__active {
  z-index: 1030 !important;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .menu-column {
    width: 100%;
    height: auto;
    padding: 0;
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (min-width: 600px) and (max-width: 991px) {
  .menu-column {
    width: 100%;
    height: auto;
    padding: 0;
  }
}

/** Mediaquerys for responsiveness of the menu */
@media screen and (max-width: 991px) {
  .bootstrap .dropdown-menu {
    background-color: transparent !important;
  }

  .left-margin-and-padding-less {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

.domain-margin-top {
  margin-top: 10px !important;
}

.keepInside {
  overflow: auto;
}
</style>
