//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import SampleStatusStore from '@/store/enums/SampleStatusStore'

computed: {
    ...mapGetters('SampleStatusStore', ['sampleStatuses'])
    },
methods:{
    ...mapActions('SampleStatusStore', ['fetchSampleStatuses']),
}
mounted() {   
  this.fetchSampleStatuses()

*/

@Module({ namespaced: true })
export default class SampleStatusStore extends VuexModule {
  SampleStatus: any[] = []

  get sampleStatuses() {
    return this.SampleStatus
  }

  @Mutation
  setSampleStatus(SampleStatus: any) {
    this.SampleStatus = SampleStatus
  }

  @Action({ commit: 'setSampleStatus' })
  async fetchSampleStatuses() {
    if (this.SampleStatus.length == 0) {
      try {
        const result = await axios.get(apiURL + '/sampleStatuses')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SampleStatus
    }
  }
}
