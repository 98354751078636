import { Module, VuexModule, Mutation /*, Action */ } from 'vuex-module-decorators'

/* https://github.com/championswimmer/vuex-module-decorators */

@Module({ namespaced: true })
export default class AuthenticationStore extends VuexModule {
  AuthCounter = 0

  get authCounter() {
    return this.AuthCounter
  }

  @Mutation
  incAuthCounter() {
    this.AuthCounter++
  }

  @Mutation
  resetAuthCounter() {
    this.AuthCounter = 0
  }
}
