import { TermItemDefinition } from '@/services/term'
import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */

@Module({ namespaced: true })
export default class SearchApplicationUserStore extends VuexModule {
  ApplicationUser_agateId: number | null = null
  ApplicationUser_applicationRoleId: number | null = null
  ApplicationUser_email: string | null = null
  ApplicationUser_status: string | null = null
  useTerms = true
  items: TermItemDefinition[] = [
    { key: 'ApplicationUser_agateId', operation: ':' },
    { key: 'ApplicationUser_applicationRoleId', operation: ':' },
    { key: 'ApplicationUser_email', operation: '~' },
    { key: 'ApplicationUser_status', operation: ':' }
  ]
}
