//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import DeliveryStopStore from '@/store/enums/DeliveryStopStore'

computed: {
    ...mapGetters('DeliveryStopStore', ['deliveryStop'])
    },
methods:{
    ...mapActions('DeliveryStopStore', ['fetchDeliveryStop']),
}
mounted() {   
  this.fetchDeliveryStop()
}

*/

@Module({ namespaced: true })
export default class DeliveryStopStore extends VuexModule {
  DeliveryStop: any[] = []

  get deliveryStop() {
    return this.DeliveryStop
  }

  @Mutation
  setDeliveryStop(DeliveryStop: any) {
    this.DeliveryStop = DeliveryStop
  }

  @Action({ commit: 'setDeliveryStop' })
  async fetchDeliveryStop() {
    if (this.DeliveryStop.length == 0) {
      try {
        const result = await axios.get(apiURL + '/deliveryStop')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.DeliveryStop
    }
  }
}
