//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
import SegmentationStatusStore from '@/store/enums/SegmentationStatusStore'

computed: {
    ...mapGetters('SegmentationStatusStore', ['segmentationStatuses'])
    },
methods:{
    ...mapActions('SegmentationStatusStore', ['fetchSegmentationStatuses']),
}
mounted() {   
  this.fetchSegmentationStatuses()

*/

@Module({ namespaced: true })
export default class SegmentationStatusStore extends VuexModule {
  SegmentationStatuses: any[] = []
  SegmentationStatusesIncVirtual: any[] = []

  get segmentationStatuses() {
    return this.SegmentationStatuses
  }

  get segmentationStatusesCorrection() {
    let arr = this.SegmentationStatuses
    arr.splice(2, 0, { canClose: true, canCloseAdmin: true, ident: 21, includes: null, name: 'CORRECTION', nameDe: 'Korrektur', nameFr: 'Correction', nameIt: 'Correzione' })
    return arr
  }

  get segmentationStatusesIncVirtual() {
    return this.SegmentationStatusesIncVirtual
  }

  @Mutation
  setSegmentationStatuses(SegmentationStatuses: any) {
    this.SegmentationStatuses = SegmentationStatuses
  }

  @Mutation
  setSegmentationStatusesIncVirtual(SegmentationStatusesIncVirtual: any) {
    this.SegmentationStatusesIncVirtual = SegmentationStatusesIncVirtual
  }

  @Action({ commit: 'setSegmentationStatuses' })
  async fetchSegmentationStatuses() {
    if (this.SegmentationStatuses.length === 0) {
      try {
        const result = await axios.get(apiURL + '/segmentationStatus')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SegmentationStatuses
    }
  }

  @Action({ commit: 'setSegmentationStatusesIncVirtual' })
  async fetchSegmentationStatusesIncVirtual() {
    if (this.SegmentationStatusesIncVirtual.length === 0) {
      try {
        const result = await axios.get(apiURL + '/segmentationStatus?includeVirtual=true')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SegmentationStatusesIncVirtual
    }
  }
}
