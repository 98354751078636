import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'
import moment from 'moment'

/*
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchProductionStore extends VuexModule {
  subsidyApproved: string | null = null
  status: string | null = 'VALIDATION'
  animalTypeId: number | null = null
  labelTypeId: number | null = null
  validFrom: string | null = moment().startOf('year').format('YYYY-MM-DD')
  validUntil: string | null = null
  isCorrection: boolean | null = null
  complied: boolean | null = null
  loadPreviousMonths: string | null = null
  createdFrom: string | null = null
  createdUntil: string | null = null
  quantityFrom: number | null = null
  quantityTo: number | null = null
  accumulate: string | null = null
  latestRowsOnly: boolean = true
  milkQualityTesting: boolean | null = null
  sapNo: number | null = null
  correction: number | null = null
  period: string | null = moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD')

  items: TermItemDefinition[] = [
    { key: 'subsidyApproved', operation: ':', itemType: 'string' },
    { key: 'status', operation: ':', itemType: 'string' },
    { key: 'animalTypeId', operation: ':', itemType: 'number' },
    { key: 'labelTypeId', operation: ':', itemType: 'number' },
    { key: 'validFrom', operation: '>=' },
    { key: 'validUntil', operation: '<=' },
    { key: 'isCorrection', operation: ':', itemType: 'boolean' },
    { key: 'complied', operation: ':', itemType: 'boolean' },
    { key: 'loadPreviousMonths', operation: ':', itemType: 'string' },
    { key: 'createdFrom', operation: ':' },
    { key: 'createdUntil', operation: ':' },
    { key: 'quantityFrom', operation: ':' },
    { key: 'quantityTo', operation: ':' },
    { key: 'accumulate', operation: ':' },
    { key: 'latestRowsOnly', operation: ':', itemType: 'boolean' },
    { key: 'milkQualityTesting', operation: ':', itemType: 'boolean' },
    { key: 'sapNo', operation: ':' },
    { key: 'correction', operation: ':' },
    { key: 'period', operation: ':', itemType: 'string' }
  ]
}
