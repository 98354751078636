import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchMasterdataStore extends VuexModule {
  legalEntities: boolean = true
  farms: boolean = false
  dairies: boolean = false
  name: string | null = null
  address: string | null = null
  ident: string | null = null
  contact: string | null = null

  items: TermItemDefinition[] = [
    { key: 'legalEntities', operation: ':' },
    { key: 'farms', operation: ':' },
    { key: 'dairies', operation: ':' },
    { key: 'name', operation: '~' },
    { key: 'address', operation: '~' },
    { key: 'ident', operation: '~' },
    { key: 'contact', operation: '~' }
  ]
}
