<template>
  <div></div>
</template>

<script>
import { authService } from '@/services/auth.service'

export default {
  name: 'OidcCallback',
  created() {
    authService
      .signinCallback(location.href)
      .then((user) => {
        this.$router.push({ name: 'home' }).catch((e) => {
          /* duplicated route */
        })
      })
      .catch((err) => {
        console.error('oidcCallback:21  :: ', err)
        // Handle errors any way you want
        this.$router.push({ name: 'home' }).catch((e) => {
          /* duplicated route */
        })
      })
  }
}
</script>
