//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
import FormStatusStore from '@/store/enums/FormStatusStore'

computed: {
    ...mapGetters('FormStatusStore', ['formStatus'])
    },
methods:{
    ...mapActions('FormStatusStore', ['fetchFormStatus']),
}
mounted() {   
  this.fetchFormStatus()

*/

@Module({ namespaced: true })
export default class FormStatusStore extends VuexModule {
  FormStatus: any[] = []

  get formStatus() {
    return this.FormStatus
  }

  @Mutation
  setFormStatus(FormStatus: any) {
    this.FormStatus = FormStatus
  }

  @Action({ commit: 'setFormStatus' })
  async fetchFormStatus() {
    if (this.FormStatus.length == 0) {
      try {
        const result = await axios.get(apiURL + '/formStatus')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.FormStatus
    }
  }
}
