import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
import SegmentationTypesStore from '@/store/enums/SegmentationTypesStore'

computed: {
    ...mapGetters('SegmentationTypesStore', ['segmentationTypes'])
    },
*/

@Module({ namespaced: true })
export default class SegmentationTypesStore extends VuexModule {
  SegmentationTypes: any[] = [
    {
      name: 'A',
      nameDe: 'A-Milch',
      nameFr: 'A-lait',
      nameIt: 'A-latte'
    },
    {
      name: 'B',
      nameDe: 'B-Milch',
      nameFr: 'B-lait',
      nameIt: 'B-latte'
    },
    {
      name: 'C',
      nameDe: 'C-Milch',
      nameFr: 'C-lait',
      nameIt: 'C-latte'
    },
    {
      name: 'BC',
      nameDe: 'B- und C-Milch',
      nameFr: 'B- et C-lait',
      nameIt: 'B- e C-latte'
    }
  ]

  get segmentationTypes() {
    return this.SegmentationTypes
  }
}
