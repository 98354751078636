//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import SubsidyInvoiceStatusesStore from '@/store/enums/SubsidyInvoiceStatusesStore'

computed: {
    ...mapGetters('SubsidyInvoiceStatusesStore', ['subsidyInvoiceStatuses'])
    },
methods:{
    ...mapActions('SubsidyInvoiceStatusesStore', ['fetchSubsidyInvoiceStatuses']),
}
mounted() {   
  this.fetchSubsidyInvoiceStatuses()

*/

@Module({ namespaced: true })
export default class SubsidyInvoiceStatusesStore extends VuexModule {
  SubsidyInvoiceStatuses: any[] = []

  get subsidyInvoiceStatuses() {
    return this.SubsidyInvoiceStatuses
  }

  @Mutation
  setSubsidyInvoiceStatuses(SubsidyInvoiceStatuses: any) {
    this.SubsidyInvoiceStatuses = SubsidyInvoiceStatuses
  }

  @Action({ commit: 'setSubsidyInvoiceStatuses' })
  async fetchSubsidyInvoiceStatuses() {
    if (this.SubsidyInvoiceStatuses.length == 0) {
      try {
        const result = await axios.get(apiURL + '/subsidyInvoiceStatuses')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SubsidyInvoiceStatuses
    }
  }
}
