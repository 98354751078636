import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchDairyStore extends VuexModule {
  Dairy_ident: number | null = null
  Dairy_id: number | null = null
  Dairy_berNo: number | null = null
  Dairy_dairyTypeId: number | null = null
  Dairy_iban: number | null = null
  Dairy_sapNo: number | null = null
  Dairy_permitNo: number | null = null
  Dairy_personId: number | null = null
  Dairy_responsibilityGroup: number | null = null
  Dairy_tsmOffice: number | null = null
  Dairy_status: number | null = null
  Dairy_cantonId: number | null = null
  Dairy_productionReportingObligation: number | null = null
  Dairy_processingReportingObligation: number | null = null
  Dairy_segmentationReportingObligation: number | null = null
  validUntil: string | null = null
  useTerms = true
  items: TermItemDefinition[] = [
    { key: 'Dairy_ident', operation: ':', itemType: 'string', isList: true },
    { key: 'Dairy_id', operation: ':' },
    { key: 'Dairy_berNo', operation: ':' },
    { key: 'Dairy_dairyTypeId', operation: ':' },
    { key: 'Dairy_iban', operation: ':' },
    { key: 'Dairy_sapNo', operation: ':' },
    { key: 'Dairy_permitNo', operation: ':' },
    { key: 'Dairy_personId', operation: ':' },
    { key: 'Dairy_responsibilityGroup', operation: ':' },
    { key: 'Dairy_tsmOffice', operation: ':' },
    { key: 'Dairy_status', operation: ':', itemType: 'number' },
    { key: 'Dairy_cantonId', operation: ':' },
    { key: 'Dairy_productionReportingObligation', operation: ':' },
    { key: 'Dairy_processingReportingObligation', operation: ':' },
    { key: 'Dairy_segmentationReportingObligation', operation: ':' },
    { key: 'validUntil', operation: ':' }
  ]
}
