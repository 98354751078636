import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
import SubsidyTypesStore from '@/store/enums/SubsidyTypesStore'

computed: {
    ...mapGetters('SubsidyTypesStore', ['subsidyTypes'])
    },
*/

@Module({ namespaced: true })
export default class SubsidyTypesStore extends VuexModule {
  SubsidyTypes: any[] = [
    { name: 'PRODUCTION', nameDe: 'Verkehrsmilch', nameFr: 'Lait commercialisé', nameIt: 'Latte commerzializzato' },
    {
      name: 'PROCESSING',
      nameDe: 'Verkäsung/Silofrei',
      nameFr: 'Fromage/sans ensilage',
      nameIt: 'Formaggio/senza insilati'
    }
  ]

  get subsidyTypes() {
    return this.SubsidyTypes
  }
}
