import { TermItem, TermItemDefinition } from '@/services/term'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import axios, { showError } from '@/services/axios'
import { apiURL } from '@/main'

export class ContactAttribute {
  id = '' // path without permission, without language, eg : /processingform, or /marketedMilks/findfarmermilk
  nameDe = '' // read | write | anything
  nameFr = '' // read | write | anything
  nameIt = '' // read | write | anything
  value = ''
}

@Module({ namespaced: true })
export default class SearchContactAttributesRelationsPickerStore extends VuexModule {
  ContactAttribute: ContactAttribute[] = []
  contactParentId: number | null = null
  useTerms = true
  items: TermItemDefinition[] = [
    { key: 'contactParentId', operation: ':' },
    {
      key: 'ContactAttribute',
      operation: ':',
      itemType: 'objects'
    }
  ]

  @Mutation
  setContactAttributes(atr: ContactAttribute[]) {
    this.ContactAttribute = atr
  }

  @Action({ commit: 'setContactAttributes' })
  async fetchContactAttributes(forced: boolean | undefined) {
    console.log('fetched: SearchContactAttributesRelationsPickerStore')
    if (this.ContactAttribute.length === 0 || forced === true) {
      try {
        const result = await axios.get(apiURL + '/contactAttributes/search')
        result.data.map((item: ContactAttribute) => (item.value = ''))
        return result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.ContactAttribute
    }
  }
}
