import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '.'

/* https://github.com/championswimmer/vuex-module-decorators */

export class Dairy {
  id = ''
  ident = 0
  name = ''
}

@Module({ namespaced: true })
export default class OwnManagedDairiesStore extends VuexModule {
  dairies: Dairy[] = []

  @Mutation
  setDairies(dairies: []) {
    this.dairies = dairies
  }

  get getDairies(): Dairy[] {
    return this.dairies
  }

  @Action({ commit: 'setDairies', rawError: true })
  async resetOwnManagedDairies() {
    return []
  }

  @Action({ commit: 'setDairies', rawError: true })
  async fetchOwnManagedDairies() {
    if (this.dairies.length === 0) {
      try {
        const response = await axios.get(apiURL + '/legalEntities/' + store.state.PersonsStore.selectedPersonId + '/dairies')
        if (await response.data) {
          return response.data
        }
      } catch (e) {
        showError(e)
      }
    } else {
      return this.dairies
    }
  }
}
