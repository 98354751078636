//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
import ResponsibilityGroupsStore from '@/store/enums/ResponsibilityGroupsStore'

computed: {
    ...mapGetters('ResponsibilityGroupsStore', ['responsibilityGroups','workingPlaces'])
    },
methods:{
    ...mapActions('ResponsibilityGroupsStore', ['fetchResponsibilityGroups']),
}
mounted() {
  this.fetchResponsibilityGroups()
*/

@Module({ namespaced: true })
export default class ResponsibilityGroupsStore extends VuexModule {
  ResponsibilityGroups: any[] = []
  WorkingPlaces: any[] = []

  get responsibilityGroups() {
    return this.ResponsibilityGroups
  }

  get workingPlaces() {
    return this.WorkingPlaces
  }

  @Mutation
  setResponsibilityGroups(ResponsibilityGroups: any) {
    this.ResponsibilityGroups = ResponsibilityGroups
  }

  @Action({ commit: 'setResponsibilityGroups' })
  async fetchResponsibilityGroups() {
    if (this.ResponsibilityGroups.length === 0) {
      try {
        const result = await axios.get(apiURL + '/responsibilityGroup/find')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.ResponsibilityGroups
    }
  }

  @Mutation
  setWorkingPlaces(WorkingPlaces: any) {
    this.WorkingPlaces = WorkingPlaces
  }

  @Action({ commit: 'setWorkingPlaces' })
  async fetchWorkingPlaces() {
    if (this.WorkingPlaces.length === 0) {
      try {
        const result = await axios.get(apiURL + '/tsmOffices/find')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.WorkingPlaces
    }
  }
}
