//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
computed: {
    ...mapGetters('FormPeriodsStore', ['formPeriods'])
    },
methods:{
    ...mapActions('FormPeriodsStore', ['fetchFormPeriods']),
}
mounted() {   
  this.fetchFormPeriods()

*/

@Module({ namespaced: true })
export default class FormPeriodsStore extends VuexModule {
  FormPeriods: any[] = []

  get formPeriods() {
    return this.FormPeriods
  }

  @Mutation
  setFormPeriods(FormPeriods: any) {
    this.FormPeriods = FormPeriods
  }

  @Action({ commit: 'setFormPeriods' })
  async fetchFormPeriods() {
    if (this.FormPeriods.length == 0) {
      try {
        const result = await axios.get(apiURL + '/processingFormTemplates/formPeriods')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.FormPeriods
    }
  }
}
