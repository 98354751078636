//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import DairyTypesStore from '@/store/enums/DairyTypesStore'

computed: {
    ...mapGetters('DairyTypesStore', ['dairyTypes'])
    },
methods:{
    ...mapActions('DairyTypesStore', ['fetchDairyTypes']),
}
mounted() {   
  this.fetchDairyTypes()

*/

@Module({ namespaced: true })
export default class DairyTypesStore extends VuexModule {
  DairyTypes: any[] = []
  DairyTypesIncVirtual: any[] = []

  get dairyTypes() {
    return this.DairyTypes
  }

  get dairyTypesIncVirtual() {
    return this.DairyTypesIncVirtual
  }

  @Mutation
  setDairyTypes(DairyTypes: any) {
    this.DairyTypes = DairyTypes
  }

  @Mutation
  setDairyTypesIncVirtual(DairyTypesIncVirtual: any) {
    this.DairyTypesIncVirtual = DairyTypesIncVirtual
  }

  @Action({ commit: 'setDairyTypes' })
  async fetchDairyTypes() {
    if (this.DairyTypes.length === 0) {
      try {
        const result = await axios.get(apiURL + '/dairyTypes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.DairyTypes
    }
  }

  @Action({ commit: 'setDairyTypesIncVirtual' })
  async fetchDairyTypesIncVirtual() {
    if (this.DairyTypesIncVirtual.length === 0) {
      try {
        const result = await axios.get(apiURL + '/dairyTypes?includeVirtual=true')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.DairyTypesIncVirtual
    }
  }
}
