import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchFarmStore extends VuexModule {
  Farm_id: number | null = null
  Farm_agisId: string | null = null
  Farm_berNo: number | null = null
  Farm_tvdId: number | null = null
  Farm_cantonalId: string | null = null
  Farm_farmTypeId: number | null = null
  Farm_status: number | null = null
  Farm_cantonId: number | null = null
  useTerms = true
  items: TermItemDefinition[] = [
    { key: 'Farm_id', operation: ':' },
    { key: 'Farm_agisId', operation: ':', itemType: 'string', isList: true },
    { key: 'Farm_berNo', operation: ':' },
    { key: 'Farm_tvdId', operation: ':' },
    { key: 'Farm_cantonalId', operation: ':' },
    { key: 'Farm_farmTypeId', operation: ':' },
    { key: 'Farm_status', operation: ':', itemType: 'number' },
    { key: 'Farm_cantonId', operation: ':' }
  ]
}
