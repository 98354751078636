import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class ProductionAreasStore extends VuexModule {
  ProductionAreas: any[] = [
    { id: 1, nameDe: 'Talgebiet', nameFr: 'Région de plaine', nameIt: 'Regione di pianura' },
    { id: 2, nameDe: 'Berggebiet', nameFr: 'Région de montagne', nameIt: 'Regione di montagna' },
    { id: 3, nameDe: 'Sömmerungsgebiet', nameFr: 'Zone intermédiaire', nameIt: "Regione d'estivazione" },
    { id: 99, nameDe: 'Nicht zugeteilt', nameFr: 'Non attribué', nameIt: 'Non classificato' }
  ]

  get productionAreas() {
    return this.ProductionAreas
  }

  /*
    @Mutation
    setReportingObligations(ProductionAreas: any) {
      this.ProductionAreas = ProductionAreas
    }
  
    @Action({ commit: 'setProductionAreas' })
    async fetchProductionAreas() {
      // check privileges, call of unprivileged api leads to an error
      if (this.ProductionAreas.length === 0 && privileges.has({ path: '/productionAreas', permission: 'read' })) {
        try {
          const result = await axios.get(apiURL + '/productionAreas')
          return await result.data
        } catch (e) {
          showError(e)
        }
      } else {
        return this.ProductionAreas
      }
    }
  */
}
