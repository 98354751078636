import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchTranslationStore extends VuexModule {
  textDe: string | null = null
  textFr: string | null = null
  textIt: string | null = null
  messageKey: string | null = null

  items: TermItemDefinition[] = [
    { key: 'textDe', operation: ':' },
    { key: 'textFr', operation: ':' },
    { key: 'textIt', operation: ':' },
    { key: 'messageKey', operation: ':' }
  ]
}
