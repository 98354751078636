import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchProcessingGroupingStore extends VuexModule {
  groupByDairy: string | null = null
  groupByDate: string | null = null
  groupByBlock: string | null = null
  groupByLabel: string | null = null

  items: TermItemDefinition[] = [
    { key: 'groupByDairy', operation: ':', itemType: 'boolean' },
    { key: 'groupByDate', operation: ':', itemType: 'boolean' },
    { key: 'groupByBlock', operation: ':', itemType: 'boolean' },
    { key: 'groupByLabel', operation: ':', itemType: 'boolean' }
  ]
}
