//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
import CantonsCH from '@/store/enums/FarmTypesStore'

computed: {
    ...mapGetters('FarmTypes', ['farmTypes'])
    },
methods:{
    ...mapActions('FarmTypes', ['fetchFarmTypes']),
}
mounted() {   
  this.fetchFarmTypes()
*/

@Module({ namespaced: true })
export default class FarmTypesStore extends VuexModule {
  FarmTypes: any[] = []

  get allFarmTypes() {
    return this.FarmTypes
  }

  get farmTypes() {
    return this.FarmTypes.filter(function (farmType) {
      return [1, 2, 5, 6, 14, 99, 101, 102, 114].includes(farmType.id)
    })
  }

  @Mutation
  setFarmTypes(FarmTypes: any) {
    this.FarmTypes = FarmTypes
  }

  @Action({ commit: 'setFarmTypes' })
  async fetchFarmTypes() {
    if (this.FarmTypes.length == 0) {
      try {
        const result = await axios.get(apiURL + '/farmTypes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.FarmTypes
    }
  }
}
