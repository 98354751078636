//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
import LabelTypesStore from '@/store/enums/LabelTypesStore'

computed: {
    ...mapGetters('LabelTypesStore', ['labelTypes'])
    },
methods:{
    ...mapActions('LabelTypesStore', ['fetchLabelTypes']),
}
mounted() {   
  this.fetchLabelTypes()

*/

@Module({ namespaced: true })
export default class LabelTypesStore extends VuexModule {
  LabelTypes: any[] = []

  get labelTypes() {
    return this.LabelTypes
  }

  @Mutation
  setLabelTypes(LabelTypes: any) {
    this.LabelTypes = LabelTypes
  }

  @Action({ commit: 'setLabelTypes' })
  async fetchLabelTypes() {
    if (this.LabelTypes.length == 0) {
      try {
        const result = await axios.get(apiURL + '/labelTypes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.LabelTypes
    }
  }
}
