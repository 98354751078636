//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

export class ApplicationRole {
  id = '' // path without permission, without language, eg : /processingform, or /marketedMilks/findfarmermilk
  nameDe = '' // read | write | anything
  nameFr = '' // read | write | anything
  nameIt = '' // read | write | anything
}

@Module({ namespaced: true })
export default class ApplicationRolesStore extends VuexModule {
  ApplicationRoles: ApplicationRole[] = []

  get applicationRoles() {
    return this.ApplicationRoles
  }

  @Mutation
  setApplicationRoles(newRoles: ApplicationRole[]) {
    this.ApplicationRoles = newRoles
  }

  @Action({ commit: 'setApplicationRoles' })
  async fetchApplicationRoles() {
    if (this.ApplicationRoles.length == 0) {
      try {
        const result = await axios.get(apiURL + '/applicationRoles')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.ApplicationRoles
    }
  }
}
