import { privileges, Privilege } from '@/services/privileges'

const Privileges = {
  install(Vue: any, options: any) {
    Vue.prototype.privileges = privileges
    Vue.prototype.$privileges = privileges
  }
}

export default Privileges
