import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'
import moment from 'moment'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchLabelAffiliationStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchLabelAffiliationStore extends VuexModule {
  labelBundle: number | null = null
  minValidFrom: string | null = null
  maxValidUntil: string | null = null
  dueDate: string | null = null

  items: TermItemDefinition[] = [
    { key: 'labelBundle', operation: ':', itemType: 'number' },
    { key: 'minValidFrom', operation: ':' },
    { key: 'maxValidUntil', operation: ':' },
    { key: 'dueDate', operation: ':' }
  ]
}
