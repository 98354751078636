//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import MessageTypesStore from '@/store/enums/MessageTypesStore'

computed: {
    ...mapGetters('MessageTypesStore', ['messageTypes'])
    },
methods:{
    ...mapActions('MessageTypesStore', ['fetchMessageTypes']),
}
mounted() {   
  this.fetchMessageTypes()

*/

@Module({ namespaced: true })
export default class MessageTypesStore extends VuexModule {
  MessageTypes: any[] = []

  get messageTypes() {
    return this.MessageTypes
  }

  @Mutation
  setMessageTypes(MessageTypes: any) {
    this.MessageTypes = MessageTypes
  }

  @Action({ commit: 'setMessageTypes' })
  async fetchMessageTypes() {
    if (this.MessageTypes.length == 0) {
      try {
        const result = await axios.get(apiURL + '/messageTypes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.MessageTypes
    }
  }
}
