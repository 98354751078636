<template>
  <div v-if="languageService.loading" class="header-bar-right margin-left center-vertically" id="domSwitch">
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
  <div v-else class="language-width">
    <v-select class="margin-top" hide-details="auto" v-if="availableLanguages" :items="availableLanguages" v-model="selectedLanguage">
      <template v-slot:append>
        <v-icon dbmpassivgrey>mdi-web</v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
import { Languages, language } from '../services/language'

export default {
  data() {
    return {
      languageService: language
    }
  },
  computed: {
    availableLanguages() {
      return Languages.map((lang) => lang.toUpperCase())
    },
    selectedLanguage: {
      get() {
        try {
          //timing issue navigation/languages is not in router-view sometimes its to fast ..
          return this.$route.params.lang.toUpperCase()
        } catch (e) {
          return 'de'
        }
      },
      set(newValue) {
        if (newValue != language.selectedLanguage) {
          newValue = newValue.toLowerCase()
          language.pushRoute(newValue)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.language-width {
  width: 60px;
}

.margin-top {
  margin-top: 5px !important;
}
</style>
