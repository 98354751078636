import { Module, VuexModule, Mutation /*, Action */ } from 'vuex-module-decorators'

/* https://github.com/championswimmer/vuex-module-decorators */

@Module
export default class Toast extends VuexModule {
  toasts: unknown[] = []

  @Mutation
  addToast(toast: unknown) {
    this.toasts.push(toast)
  }
}
