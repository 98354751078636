<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span v-html="$t('subsidy_invoice_document_dialog_header')" />
      </v-card-title>
      <v-card-text>
        <div>
          <v-alert border="top" colored-border type="info" elevation="2">
            <span v-html="$t('subsidy_invoice_document_dialog_info')" />
            <router-link :to="{ name: 'inbox_read', query: { term: 'documentTypeGroup:DOWNLOADS', size: 50 } }" class="link" v-if="privileges.has({ path: '/documents/inbox', permission: 'read' })">
              <span v-html="$t('subsidy_invoice_document_dialog_link')" />
            </router-link>
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="closeDialog()">
          <span v-html="$t('close')" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { vueApp } from '@/main'

export default {
  name: 'download_info_dialog',
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    openDownloadInfoDialog() {
      this.dialog = true
    }
  },
  mounted() {
    vueApp.$on('openDownloadInfoDialog', this.openDownloadInfoDialog)
  }
}
</script>
<style lang="scss"></style>
