<template>
  <div class="container-xl container bradcrumb-padding d-print-none">
    <div v-if="navTree" class="breadbrumbs">
      <span v-if="typeof selectedNav1 != 'undefined'"> <v-icon dbmpassivgrey size="12px">mdi-home</v-icon> {{ selectedNav1.label[languageKey] }} </span>

      <span v-if="typeof selectedNav2 != 'undefined'"
        ><v-icon dbmpassivgrey size="12px">mdi-arrow-right</v-icon>
        {{ selectedNav2.label[languageKey] }}
      </span>

      <span v-if="typeof selectedNav3 != 'undefined'">
        <v-icon dbmpassivgrey size="12px">mdi-arrow-right</v-icon>
        {{ selectedNav3.label[languageKey] }}
      </span>
    </div>
  </div>
</template>
<script>
import { navTree } from '../router/navTree'

export default {
  name: 'navigationbreadcrumb',
  data() {
    return {
      navTree: navTree,
      activeItemPath: [],
      routeInfo: { path: 'nope' }
    }
  },
  computed: {
    selectedNav1() {
      return this.navTree.filter((elem) => elem.selectedPath)[0]
    },
    selectedNav2() {
      if (!this.selectedNav1) return
      return this.selectedNav1.children.filter((elem) => elem.selectedPath)[0]
    },
    selectedNav3() {
      if (!this.selectedNav2) return
      return this.selectedNav2.children.filter((elem) => elem.selectedPath)[0]
    },
    languageKey() {
      return this.$route.params.lang
    }
  }
}
</script>
<style lang="scss">
.bradcrumb-padding {
  padding-top: $spacer-xs !important;
  padding-bottom: $spacer-xxxs !important;
}

.breadbrumbs {
  font-size: $font-xs;
}

.material-icons {
  vertical-align: middle !important;
}
</style>
