import { vueApp } from '@/main'
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import axios from '@/services/axios'

const oidc_keycloak_dev = {
  authority: 'http://localhost:8198/auth/realms/dbm',
  clientId: 'dbm-webapp',
  clientRoot: 'http://localhost:8080',
  scope: 'openid profile',
  post_logout_redirect_uri: 'http://localhost:8080'
}
const oidc_keycloak_prod = {
  authority: 'https://' + location.hostname + '/auth/realms/dbm',
  clientId: 'dbm-webapp',
  clientRoot: 'https://' + location.hostname,
  scope: 'openid profile',
  post_logout_redirect_uri: 'https://' + location.hostname
}
const oidc_agate_integration = {
  authority: 'https://idp-rf.agate.ch/auth/realms/agate',
  clientId: 'milchdb-rf',
  clientRoot: 'https://' + location.hostname,
  scope: 'openid loginid',
  post_logout_redirect_uri: 'https://rf.agate.ch/portal/?login'
}
const oidc_agate_prod = {
  authority: 'https://idp.agate.ch/auth/realms/agate',
  clientId: 'milchdb',
  clientRoot: 'https://' + location.hostname,
  scope: 'openid loginid',
  post_logout_redirect_uri: 'https://agate.ch/portal/?login'
}

function getOIDC_config(): any {
  if (process.env.NODE_ENV === 'development') return oidc_keycloak_dev
  switch (window.location.hostname.split('.')[0]) {
    case 'prod':
      return oidc_agate_prod
    case 'integration':
      return oidc_agate_integration
    case 'test':
      return oidc_keycloak_prod
  }
  return null
}

export function getOidcSettings(): UserManagerSettings {
  const environment = getOIDC_config()
  return {
    authority: environment.authority,
    client_id: environment.clientId,
    redirect_uri: `${environment.clientRoot}/oidc-callback`,
    silent_redirect_uri: `${environment.clientRoot}/oidc-callback`,
    post_logout_redirect_uri: environment.post_logout_redirect_uri,
    response_type: 'code',
    scope: environment.scope,
    userStore: new WebStorageStateStore({ store: window.localStorage })
  }
}

const environment = getOIDC_config()

class AuthService {
  private static _instance: AuthService

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  private userManager: UserManager = new UserManager(getOidcSettings())
  public loadedUser: User | null = null

  constructor() {
    //https://authts.github.io/oidc-client-ts/classes/UserManagerEvents.html
    this.userManager.events.addUserLoaded(() => {
      this.userManager.getUser().then((user) => {
        this.loadedUser = user
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + user?.access_token
        console.log('emit oidcUserLoaded')
        vueApp.$emit('oidcUserLoaded')
      })
    })
    this.userManager.events.addUserUnloaded(() => {
      console.log('emit UserUnloaded')
      this.loadedUser = null
      axios.defaults.headers.common['Authorization'] = ''
      vueApp.$emit('oidcUserUnloaded')
    })
    this.userManager.events.addSilentRenewError(() => {
      console.log('emit SilentRenewError')
      vueApp.$emit('oidcSilentRenewError')
    })
  }

  public async getUser(): Promise<User | null> {
    let user = await this.userManager.getUser()
    this.loadedUser = user
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user?.access_token
    return user
  }

  public login(): Promise<User | void> {
    //https://authts.github.io/oidc-client-ts/classes/UserManager.html#signinPopup
    return this.userManager.signinRedirect()
  }

  public renewToken(): Promise<User | null> {
    return this.userManager.signinSilent()
  }

  public signinCallback(url: any): Promise<User | void> {
    return this.userManager.signinCallback(url)
  }

  public signinRedirect(): Promise<void> {
    return this.userManager.signinRedirect()
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect()
  }
}

export const authService = AuthService.Instance
