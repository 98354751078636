import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchProcessingFormTemplateStore extends VuexModule {
  Dairy_animalTypeId: number | null = null
  Dairy_dairyProcessing: number | null = null
  Dairy_formPeriodId: number | null = null
  Dairy_hasSubsidyProduct: number | null = null

  items: TermItemDefinition[] = [
    { key: 'Dairy_animalTypeId', operation: ':' },
    { key: 'Dairy_dairyProcessing', operation: ':' },
    { key: 'Dairy_formPeriodId', operation: ':' },
    { key: 'Dairy_hasSubsidyProduct', operation: ':' }
  ]
}
