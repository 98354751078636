import Axios, { AxiosError, AxiosResponse } from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import { vueApp, i18n } from '../main'
import store from '../store'

import { CustomFormatter } from '@/views/translations/CustomFormatter'
import qs from 'qs'

const axios = Axios.create() // import axios from '.main'
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.request.use(
  (config) => {
    const newConfig = {
      ...config,
      params: {
        ...config.params,
        ...{
          originId: vueApp.$store.getters['getOriginId'] ? store.getters['getOriginId'] : null,
          domainId: vueApp.$store.getters['getSelectedPersonId'] ? store.getters['getSelectedPersonId'] : null,
          userId: vueApp.$store.getters['getSelectedApplicationUserId'] ? store.getters['getSelectedApplicationUserId'] : null
        }
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat', filter: filterFunc })
      }
    }
    return newConfig
  },
  (e) => Promise.reject(e)
)

function filterFunc(prefix, value) {
  if (value === null) {
    // Return an `undefined` value to omit a property.
    return
  }
  return value
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    let errorMessage = ''
    if (error.response?.status === 401 || error.response?.status === 403) {
      errorMessage = error?.response?.data.detail
      const translatedError = i18n.t(error?.response?.data.title, error?.response?.data.placeholders)
      const formatter = new CustomFormatter()
      const formattedErrorMessage = formatter.interpolate(errorMessage, error?.response?.data.placeholders) ?? errorMessage

      vueApp.$toasted.global.error({
        message: formattedErrorMessage
      })

      if (error.response?.status === 401) {
        vueApp.$emit('backendUnAuthorized')
      }
    }
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, axios)
export default axios

/* if the response contain a header: "content-diposition filename=foobar.txt" it will overwrite your fileName */
export function fileCreator(response, fileName) {
  let fname = 'file.txt'
  if (fileName && fileName.length) {
    fname = fileName
  }
  if (response.headers['content-disposition'] && response.headers['content-disposition'].match(/filename=/i)) {
    var disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('filename') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      var matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) fname = matches[1].replace(/['"]/g, '')
    }
  }
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fname) //or any other extension
  document.body.appendChild(link)
  link.click()
  return true
}

export function isXHRError(error) {
  return typeof error.response?.status === 'number' ? true : false
}
export function isAJAXError(error) {
  return isXHRError(error)
}
export function isAxiosError(error) {
  return isXHRError(error)
}

export function showError(error, type) {
  if (!isXHRError(error)) {
    if (type == 'inline') {
      return JSON.stringify(error)
    } else {
      vueApp.$toasted.global.error({
        message: vueApp.$t('toast_generic_error_message') + ' :: ' + JSON.stringify(error)
      })
    }
  }

  if (error.response?.status === 401 || error.response?.status === 403) {
    // already caught by axios interceptor above
    return true
  }

  if (error?.response?.data?.detail) {
    if (Math.floor(error?.response?.data?.status / 100) == 5 && !vueApp.$privileges.has({ path: '/debug', permission: 'show' })) {
      vueApp.$toasted.global.error({
        message: vueApp.$t('toast_generic_error_message')
      })
      return
    }

    //its a custom error
    let errorMessage = error?.response?.data.detail
    const translatedError = i18n.t(error?.response?.data.title, error?.response?.data.placeholders)
    const formatter = new CustomFormatter()
    let formattedErrorMessage = formatter.interpolate(errorMessage, error?.response?.data.placeholders) ?? errorMessage
    if (type == 'inline') {
      return translatedError !== error?.response?.data.title ? formatter.interpolate(translatedError, error?.response?.data.placeholders) : formattedErrorMessage
    } else {
      // toasted
      if (error.response.data.title.match(/DBM_ERROR/)) {
        // if the DBM_ERROR is translated
        if (vueApp.$t(error.response.data.title) != error.response.data.title) {
          errorMessage = vueApp.$t(error.response.data.title)
          formattedErrorMessage = formatter.interpolate(translatedError, error?.response?.data.placeholders) ?? errorMessage
        }
        vueApp.$toasted.global.error({
          message: formattedErrorMessage
        })
      } else {
        vueApp.$toasted.global.error({
          message: vueApp.$t('toast_generic_error_message') + ' :: ' + JSON.stringify(error)
        })
      }
    }
  } else {
    // a generic java error
    if (type == 'inline') {
      return JSON.stringify(error)
    } else {
      let message = vueApp.$t('toast_generic_error_message')
      if (Math.floor(error.response?.status / 100) !== 5 || vueApp.$privileges.has({ path: '/debug', permission: 'show' })) {
        message += ' :: ' + JSON.stringify(error)
      }
      vueApp.$toasted.global.error({
        message: message
      })
    }
  }
}
