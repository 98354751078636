import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import axios, { showError } from '@/services/axios'
import { apiURL } from '@/main'

/*
import MilkLabelsStore from '@/store/enums/MilkLabelsStore'

computed: {
    ...mapGetters('MilkLabelsStore', ['milkLabels'])
    },
methods:{
    ...mapActions('MilkLabelsStore', ['fetchMilkLabels']),
}
mounted() {
  this.fetchMilkLabels()
  }
*/

@Module({ namespaced: true })
export default class MilkLabelsStore extends VuexModule {
  MilkLabels: any[] = []

  get milkLabels() {
    return this.MilkLabels
  }

  @Mutation
  setMilkLabels(MilkLabels: any) {
    this.MilkLabels = MilkLabels
  }

  @Action({ commit: 'setMilkLabels' })
  async fetchMilkLabels() {
    if (this.MilkLabels.length === 0) {
      try {
        const result = await axios.get(apiURL + '/milkLabels')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.MilkLabels
    }
  }
}
