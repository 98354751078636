//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
import AnimalTypesStore from '@/store/enums/AnimalTypesStore'

computed: {
    ...mapGetters('AnimalTypesStore', ['animalTypes'])
    },
methods:{
    ...mapActions('AnimalTypesStore', ['fetchAnimalTypes']),
}
mounted() {   
  this.fetchAnimalTypes()
*/

@Module({ namespaced: true })
export default class AnimalTypesStore extends VuexModule {
  AnimalTypes: any[] = []
  AnimalTypesIncVirtual: any[] = []
  AnimalTypesProduction: any[] = []

  get animalTypes() {
    return this.AnimalTypes
  }

  get animalTypesIncVirtual() {
    return this.AnimalTypesIncVirtual
  }

  get animalTypesProduction() {
    return this.AnimalTypesProduction
  }

  @Mutation
  setAnimalTypes(AnimalTypes: any) {
    this.AnimalTypes = AnimalTypes
  }

  @Mutation
  setAnimalTypesIncVirtual(AnimalTypesIncVirtual: any) {
    this.AnimalTypesIncVirtual = AnimalTypesIncVirtual
  }

  @Mutation
  setAnimalTypesProduction(AnimalTypesProduction: any) {
    this.AnimalTypesProduction = AnimalTypesProduction
  }

  @Action({ commit: 'setAnimalTypes' })
  async fetchAnimalTypes() {
    if (this.AnimalTypes.length === 0) {
      const result = await axios.get(apiURL + '/animalTypes')
      return await result.data
    } else {
      return this.AnimalTypes
    }
  }

  @Action({ commit: 'setAnimalTypesIncVirtual' })
  async fetchAnimalTypesIncVirtual() {
    if (this.AnimalTypesIncVirtual.length == 0) {
      const result = await axios.get(apiURL + '/animalTypes?&includeVirtual=true')
      return await result.data
    } else {
      return this.AnimalTypesIncVirtual
    }
  }

  @Action({ commit: 'setAnimalTypesProduction' })
  async fetchAnimalTypesProduction() {
    if (this.AnimalTypesProduction.length === 0) {
      const result = await axios.get(apiURL + '/animalTypes/production')
      return await result.data
    } else {
      return this.AnimalTypesProduction
    }
  }
}
