//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/*
computed: {
    ...mapGetters('OrganisationsStore', ['producerOrganisations'])
},
methods:{
    ...mapActions('OrganisationsStore', ['fetchProducerOrganisations']),
}
mounted() {
  this.fetchProducerOrganisations()
*/

@Module({ namespaced: true })
export default class OrganisationsStore extends VuexModule {
  ProducerOrganisations: any[] = []
  BuyerOrganisations: any[] = []
  ProcessingEncashmentOrganisations: any[] = []
  ProductionEncashmentOrganisations: any[] = []
  ActiveProductionEncashmentOrganisations: any[] = []
  LoadingProductionEncashmentOrganisations: boolean = false
  LoadingActiveProductionEncashmentOrganisations: boolean = false

  get producerOrganisations() {
    return this.ProducerOrganisations
  }

  get buyerOrganisations() {
    return this.BuyerOrganisations
  }

  get processingencashmentOrganisations() {
    return this.ProcessingEncashmentOrganisations
  }

  get productionEncashmentOrganisations() {
    return this.ProductionEncashmentOrganisations
  }

  get activeProductionEncashmentOrganisations() {
    return this.ActiveProductionEncashmentOrganisations
  }

  get loadingProductionEncashmentOrganisations() {
    return this.LoadingProductionEncashmentOrganisations
  }

  get loadingActiveProductionEncashmentOrganisations() {
    return this.LoadingActiveProductionEncashmentOrganisations
  }

  @Mutation
  setProducerOrganisations(ProducerOrganisations: any) {
    this.ProducerOrganisations = ProducerOrganisations
  }

  @Mutation
  setBuyerOrganisations(BuyerOrganisations: any) {
    this.BuyerOrganisations = BuyerOrganisations
  }

  @Mutation
  setProcessingEncashmentOrganisations(ProcessingEncashmentOrganisations: any) {
    this.ProcessingEncashmentOrganisations = ProcessingEncashmentOrganisations
  }

  @Mutation
  setProductionEncashmentOrganisations(ProductionEncashmentOrganisations: any) {
    this.ProductionEncashmentOrganisations = ProductionEncashmentOrganisations
  }

  @Mutation
  setActiveProductionEncashmentOrganisations(ActiveProductionEncashmentOrganisations: any) {
    this.ActiveProductionEncashmentOrganisations = ActiveProductionEncashmentOrganisations
  }

  @Mutation
  setLoadingProductionEncashmentOrganisations(loading: any) {
    this.LoadingProductionEncashmentOrganisations = loading
  }

  @Mutation
  setLoadingActiveProductionEncashmentOrganisations(loading: any) {
    this.LoadingActiveProductionEncashmentOrganisations = loading
  }

  @Action({ commit: 'setBuyerOrganisations' })
  async fetchBuyerOrganisations() {
    if (this.BuyerOrganisations.length === 0) {
      try {
        const result = await axios.get(apiURL + '/legalEntities/buyerOrganisations')
        const orgs: Array<any> = []
        if (await result) {
          result.data.forEach((org: any) => {
            orgs.push({ id: org.id, name: [org.name1, org.name2, org.uid].join(' ') })
          })
        }
        return orgs
      } catch (e) {
        showError(e)
      }
    } else {
      return this.BuyerOrganisations
    }
  }

  @Action({ commit: 'setProducerOrganisations' })
  async fetchProducerOrganisations() {
    if (this.ProducerOrganisations.length === 0) {
      try {
        const result = await axios.get(apiURL + '/legalEntities/producerOrganisations')
        const orgs: Array<any> = []
        if (await result) {
          result.data.forEach((org: any) => {
            orgs.push({ id: org.id, name: org.name1 })
          })
        }
        return orgs
      } catch (e) {
        showError(e)
      }
    } else {
      return this.BuyerOrganisations
    }
  }

  @Action({ commit: 'setProcessingEncashmentOrganisations' })
  async fetchProcessingEncashmentOrganisations() {
    if (this.ProcessingEncashmentOrganisations.length === 0) {
      return [
        { id: 54, name: 'Vereinigte Milchbauern Mitte-Ost' },
        { id: 183, name: 'Raclette Suisse' },
        { id: 184, name: 'CasAlp' }
      ]
      /*
                        try {
                          const result = await axios.get(apiURL + '/legalEntities/processngEncashmentOrganisations')
                          return await result.data
                        } catch (e) {
                          showError(e)
                        }
                        */
    } else {
      return this.ProcessingEncashmentOrganisations
    }
  }

  @Action({ commit: 'setProductionEncashmentOrganisations' })
  async fetchProductionEncashmentOrganisations() {
    this.context.commit('setLoadingProductionEncashmentOrganisations', true)
    if (this.ProductionEncashmentOrganisations.length === 0) {
      try {
        const result = await axios.get(apiURL + '/legalEntities/productionEncashmentOrganisations', { params: { includeInactive: true } })
        const orgs: Array<any> = []

        if (await result) {
          result.data.forEach((org: any) => {
            orgs.push({ id: org.id, name: org.name1, agisId: org.agisId })
          })
        }
        return orgs
      } catch (e) {
        showError(e)
      } finally {
        this.context.commit('setLoadingProductionEncashmentOrganisations', false)
      }
    } else {
      this.context.commit('setLoadingProductionEncashmentOrganisations', false)
      return this.ProductionEncashmentOrganisations
    }
  }

  @Action({ commit: 'setActiveProductionEncashmentOrganisations' })
  async fetchActiveProductionEncashmentOrganisations() {
    this.context.commit('setLoadingActiveProductionEncashmentOrganisations', true)
    if (this.ActiveProductionEncashmentOrganisations.length === 0) {
      try {
        const result = await axios.get(apiURL + '/legalEntities/productionEncashmentOrganisations', { params: { includeInactive: false } })
        const orgs: Array<any> = []

        if (await result) {
          result.data.forEach((org: any) => {
            orgs.push({ id: org.id, name: org.name1 })
          })
        }
        return orgs
      } catch (e) {
        showError(e)
      } finally {
        this.context.commit('setLoadingActiveProductionEncashmentOrganisations', false)
      }
    } else {
      this.context.commit('setLoadingActiveProductionEncashmentOrganisations', false)
      return this.ActiveProductionEncashmentOrganisations
    }
  }
}
