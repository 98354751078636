import axios from './axios'

import { vueApp, apiURL } from '@/main'
import { showError } from './axios'
import store from '@/store'
import { privileges } from '@/services/privileges'
import { Person } from '@/domain/person'

class Persons {
  public loading = false
  private static _instance: Persons
  public applicationUser: any = {}
  public legalEntities: any = []

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  ready: boolean = false

  oidcUserLoaded() {
    personsService
      .setUser()
      .then(async (checkInUser) => {
        let legalEntities = await personsService.loadLegalEntity(checkInUser)
        if (legalEntities) {
          await privileges.load()
          vueApp.$emit('reloadNav')
          return true
        }
      })
      .catch((e) => {
        console.log(e)
        return false
      })
  }

  init(applicationUser: any, legalEntities: Person[]) {
    this.applicationUser = applicationUser
    this.legalEntities = legalEntities
  }

  public async setUser() {
    try {
      const requested: any = await axios.put(apiURL + '/applicationUsers/checkIn/')
      if (await requested) {
        this.applicationUser = requested.data
        if (!(this.getSelectedApplicationUserId() > 0)) {
          // has no user-switch
          const requestedLastLogin: any = await axios.patch(apiURL + '/applicationUsers/updateLastLogin')
          store.commit('setApplicationUser', requested.data)
          this.applicationUser = requested.data
          this.applicationUser.thisLogin = requestedLastLogin.data.lastLogin
          vueApp.$emit('reloadNav')
          return this.applicationUser
        }
      }
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  async loadLegalEntity(checkInUser: any): Promise<Person[]> {
    if (store.state.PersonsStore.persons.length > 0) {
      return store.state.PersonsStore.persons.length
    }
    this.loading = true
    const response = await axios.get(apiURL + '/privileges/legalEntities') // refactor at some point
    let data = await response.data
    let persons: Person[] = await data
    store.commit('setPersons', persons)
    this.legalEntities = persons

    if (!checkInUser.lastSelectedLegalEntityId) {
      // first time
      console.log('checkin no lastSelectedLegalEntityId')
      this.setDomain(persons[0].id)
    } else if (persons.find((p) => p.id == checkInUser.lastSelectedLegalEntityId)) {
      //unchanged?
      console.log('checkin number validated')
      store.commit('setDomain', checkInUser.lastSelectedLegalEntityId)
    } else {
      // le structure changed lasteSelected not accurate anymore
      console.log('checkin not validated, fallback to first legalEntityId:', persons[0].id)
      this.setDomain(persons[0].id)
    }
    this.ready = true
    this.loading = false
    return await persons
  }

  getSelectedPerson() {
    return store.state.PersonsStore.selectedPersonId
  }

  getSelectedPersonName() {
    if (this.getSelectedApplicationUserId() > 0) {
      return store.state.PersonsStore?.persons[0]?.name1
    } else {
      return ''
    }
  }

  getSelectedApplicationUserId() {
    return store.state.PersonsStore.selectedApplicationUserId
  }

  getSelectedApplicationUserName() {
    return store.state.PersonsStore.selectedApplicationUserName
  }

  /** set/change own domain */
  async setDomain(domainId: number) {
    vueApp.$store.commit('setDomain', domainId)
    try {
      if (!personsService.getSelectedApplicationUserId()) {
        await axios.patch(apiURL + '/applicationUsers/' + this.applicationUser.id + '/updateLastSelectedLegalEntity?legalEntityId=' + domainId)
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.resetVuexPreserveUser()
      await privileges.load()
      vueApp.$emit('reloadNav')
    }
  }

  /** set/change own domain */
  async setSelectedApplicationUser(selectedApplicationUser: any) {
    if (selectedApplicationUser.applicationUserId) {
      selectedApplicationUser.id = selectedApplicationUser.applicationUserId
      selectedApplicationUser.name = selectedApplicationUser.applicationUserName
      selectedApplicationUser.email = selectedApplicationUser.applicationUserEmail
      selectedApplicationUser.allowedLegalEntities = [
        {
          id: selectedApplicationUser.legalEntityId,
          name1: selectedApplicationUser.legalEntityName1
        }
      ]
    }
    this.resetVuexPreserveUser()
    if (selectedApplicationUser == null) {
      vueApp.$store.commit('setSelectedApplicationUser', null)
      vueApp.$store.commit('setDomain', null)
    } else {
      vueApp.$store.commit('setSelectedApplicationUser', selectedApplicationUser)

      vueApp.$router.push({ name: 'home' }).catch((e) => {
        /* duplicated route */
      })
    }
    personsService
      .setUser()
      .then(async (checkInUser) => {
        let legalEntities = await personsService.loadLegalEntity(checkInUser)
        if (legalEntities) {
          await privileges.load()
          vueApp.$emit('reloadNav')
          return true
        }
      })
      .catch((e) => {
        console.log(e)
        return false
      })
  }

  /* switch to another userDomain */
  async switchDomain(legalEntity: any) {
    vueApp.$store.commit('switchDomain', legalEntity)
    this.resetVuexPreserveUser()
    await privileges.load()
    vueApp.$emit('reloadNav')
    vueApp.$router.push({ name: 'home' }).catch((e) => {
      /* duplicated route */
    })
    return true
  }

  async switchDomainAsSelectedUser(legalEntityId: number) {
    vueApp.$store.commit('setDomain', legalEntityId)
    this.resetVuexPreserveUser()
    await privileges.load()
    vueApp.$emit('reloadNav')
    vueApp.$router.push({ name: 'home' }).catch((e) => {
      /* duplicated route */
    })
    return true
  }

  /* switch to another userDomain */
  async backToOriginDomain(legalEntity: any) {
    vueApp.$store.commit('resetDomain', legalEntity) //  this.selectedPersonId = this.originLegalEntityId
    this.resetVuexPreserveUser()
    this.loadLegalEntity(this.applicationUser)
    await privileges.load()
    vueApp.$emit('reloadNav')
    vueApp.$router.push({ name: 'home' }).catch((e) => {
      /* duplicated route */
    })
    return true
  }

  resetVuexPreserveUser() {
    let personsStoreClone = { ...store.state.PersonsStore }
    store.reset()
    store.state.PersonsStore = { ...personsStoreClone }
  }

  reset() {
    this.ready = false
    this.applicationUser = {}
    this.legalEntities = []
    vueApp.$store.commit('setPersons', [])
    vueApp.$store.commit('setDomain', 0)
    try {
      delete axios.defaults.headers.common['Impersonation']
    } catch (e) {
      showError(e)
    }
  }
}

export const personsService = Persons.Instance
