import { Module, VuexModule, Mutation /*, Action */ } from 'vuex-module-decorators'
import { Privilege } from '@/services/privileges'

/* https://github.com/championswimmer/vuex-module-decorators */

@Module
export default class Privileges extends VuexModule {
  privileges: Privilege[] = []
  legacyApps = []

  @Mutation
  setPrivileges(privileges: Privilege[]) {
    this.privileges = privileges
  }

  @Mutation
  setLegacyApps(legacyApps: []) {
    this.legacyApps = legacyApps
  }
}
