//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import IssueStatusesStore from '@/store/enums/IssueStatusesStore'

computed: {
    ...mapGetters('IssueStatusesStore', ['issueStatuses'])
    },
methods:{
    ...mapActions('IssueStatusesStore', ['fetchIssueStatuses']),
}
mounted() {   
  this.fetchIssueStatuses()

*/

@Module({ namespaced: true })
export default class IssueStatusesStore extends VuexModule {
  IssueStatuses: any[] = []

  get issueStatuses() {
    return this.IssueStatuses
  }

  @Mutation
  setIssueStatuses(IssueStatuses: any) {
    this.IssueStatuses = IssueStatuses
  }

  @Action({ commit: 'setIssueStatuses' })
  async fetchIssueStatuses() {
    if (this.IssueStatuses.length == 0) {
      try {
        const result = await axios.get(apiURL + '/issueStatuses')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.IssueStatuses
    }
  }
}
