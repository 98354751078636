import { vueApp } from '@/main'
import { authService } from '@/services/auth.service'
import { DataItemsPerPageOption } from 'vuetify'

const Globals = {
  install(Vue: any, options: any) {
    Vue.prototype.$getBeta = () => {
      return vueApp.$store.state.SettingsStore.showBeta
    }

    const defaultPageOptions: DataItemsPerPageOption[] = [10, 20, 50, 100]
    Vue.prototype.$defaultFooter = {
      'items-per-page-options': defaultPageOptions
    }

    Vue.prototype.$legacyEnabled = () => {
      // Legacy-Access only with Agate-Token and without Domain-Switch
      return vueApp.$store.state.SettingsStore.oidc === 'agate' && !(vueApp.$store.state.PersonsStore.selectedPersonId != vueApp.$store.state.PersonsStore.originLegalEntityId)
    }

    Vue.prototype.$gotoLegacy = (app: string, nav: number) => {
      let legacyUrl = location.hostname === 'prod.dbmilch.ch' || location.hostname === 'prod.bdlait.ch' ? 'https://legacy.dbmilch.ch/' : 'https://test.dbmilch.ch/'

      legacyUrl += app + '/index.cfm?action=login&domainID=' + vueApp.$store.state.PersonsStore.selectedPersonId + '&jwt=' + authService.loadedUser?.access_token
      if (nav) legacyUrl += '&Nav=' + nav
      console.log(legacyUrl)
      window.open(legacyUrl, '_blank')
    }

    Vue.prototype.$goNext = (elem: any) => {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem)
      const activeElems = Array.from(elem.form.elements)
        .filter((el: any, index: number) => index > currentIndex)
        .filter((el: any) => !el.disabled)
      if (activeElems.length) {
        ;(activeElems[0] as any).focus()
      } else {
        elem.form.elements.item(0).focus()
      }
    }
  }
}

export default Globals
