//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import MilkQualitySampleTypesStore from '@/store/enums/MilkQualitySampleTypesStore'

computed: {
    ...mapGetters('MilkQualitySampleTypesStore', ['milkQualitySampleTypes'])
    },
methods:{
    ...mapActions('MilkQualitySampleTypesStore', ['fetchMilkQualitySampleTypes']),
}
mounted() {   
  this.fetchMilkQualitySampleTypes()

*/

@Module({ namespaced: true })
export default class MilkQualitySampleTypesStore extends VuexModule {
  MilkQualitySampleTypes: any[] = []

  get milkQualitySampleTypes() {
    return this.MilkQualitySampleTypes
  }

  @Mutation
  setMilkQualitySampleTypes(MilkQualitySampleTypes: any) {
    this.MilkQualitySampleTypes = MilkQualitySampleTypes
  }

  @Action({ commit: 'setMilkQualitySampleTypes' })
  async fetchMilkQualitySampleTypes() {
    if (this.MilkQualitySampleTypes.length == 0) {
      try {
        const result = await axios.get(apiURL + '/milkQualitySampleTypes')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.MilkQualitySampleTypes
    }
  }
}
