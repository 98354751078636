import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '..'

/* https://github.com/championswimmer/vuex-module-decorators */

export class ContactOrganisation {
  id = 0
  name1 = ''
  name2 = ''
}

@Module({ namespaced: true })
export default class ContactOrganisationsStore extends VuexModule {
  contactOrganisationsData: ContactOrganisation[] = []

  @Mutation
  setContactOrganisations(contactOrganisations: []) {
    this.contactOrganisationsData = contactOrganisations
  }

  get contactOrganisations(): ContactOrganisation[] {
    return this.contactOrganisationsData
  }

  @Action({ commit: 'setContactOrganisations', rawError: true })
  async resetContactOrganisations() {
    return []
  }

  @Action({ commit: 'setContactOrganisations', rawError: true })
  async fetchContactOrganisations() {
    if (this.contactOrganisationsData.length === 0) {
      try {
        const response = await axios.get(apiURL + '/contacts/searchOrganisations')
        if (await response.data) {
          return response.data
        }
      } catch (e) {
        showError(e)
      }
    } else {
      return this.contactOrganisationsData
    }
  }
}
