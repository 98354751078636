import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class ProductionZonesStore extends VuexModule {
  ProductionZones: any[] = [
    { id: 11, nameDe: 'Ackerbauzone', nameFr: 'Zone de grandes cultures', nameIt: 'Zona campicola' },
    {
      id: 21,
      nameDe: 'Erweiterte Übergangszone',
      nameFr: 'Zone intermédiaire élargie',
      nameIt: 'Zona intermedia ampliata'
    },
    { id: 22, nameDe: 'Übergangszone', nameFr: 'Zone intermédiaire', nameIt: 'Zona intermedia' },
    { id: 31, nameDe: 'Talzone', nameFr: 'Zone de plaine', nameIt: 'Zona di pianura' },
    { id: 41, nameDe: 'Hügelzone', nameFr: 'Zone des collines', nameIt: 'Zona collinare' },
    { id: 51, nameDe: 'Bergzone 1', nameFr: 'Zone de montagne 1', nameIt: 'Zona di montagna 1' },
    { id: 52, nameDe: 'Bergzone 2', nameFr: 'Zone de montagne 2', nameIt: 'Zona di montagna 2' },
    { id: 53, nameDe: 'Bergzone 3', nameFr: 'Zone de montagne 3', nameIt: 'Zona di montagna 3' },
    { id: 54, nameDe: 'Bergzone 4', nameFr: 'Zone de montagne 4', nameIt: 'Zona di montagna 4' },
    { id: 61, nameDe: 'Sömmerungsfläche', nameFr: "Surface d'estivage", nameIt: "Superficie d'estivazione" },
    { id: 99, nameDe: 'Nicht zugeteilt', nameFr: 'Non attribué', nameIt: 'Non classificato' }
  ]

  get productionZones() {
    return this.ProductionZones
  }

  /*
    @Mutation
    setReportingObligations(ProductionZones: any) {
      this.ProductionZones = ProductionZones
    }
  
    @Action({ commit: 'setProductionZones' })
    async fetchProductionZones() {
      // check privileges, call of unprivileged api leads to an error
      if (this.ProductionZones.length === 0 && privileges.has({ path: '/productionZones', permission: 'read' })) {
        try {
          const result = await axios.get(apiURL + '/productionZones')
          return await result.data
        } catch (e) {
          showError(e)
        }
      } else {
        return this.ProductionZones
      }
    }
  */
}
