//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 

import SubsidyAccountsStore from '@/store/enums/SubsidyAccountsStore'

computed: {
    ...mapGetters('SubsidyAccountsStore', ['SubsidyAccounts'])
    },
methods:{
    ...mapActions('SubsidyAccountsStore', ['fetchSubsidyAccounts']),
}
mounted() {   
  this.fetchSubsidyAccounts()

*/

@Module({ namespaced: true })
export default class SubsidyAccountsStore extends VuexModule {
  SubsidyAccounts: any[] = []
  SubsidyAccountsProduction: any[] = []
  SubsidyAccountsProcessing: any[] = []

  get subsidyAccounts() {
    return this.SubsidyAccounts
  }

  get subsidyAccountsProduction() {
    return this.SubsidyAccountsProduction
  }

  get subsidyAccountsProcessing() {
    return this.SubsidyAccountsProcessing
  }

  @Mutation
  setSubsidyAccounts(SubsidyAccounts: any) {
    this.SubsidyAccounts = SubsidyAccounts
  }

  @Mutation
  setSubsidyAccountsProduction(SubsidyAccountsProduction: any) {
    this.SubsidyAccountsProduction = SubsidyAccountsProduction
  }

  @Mutation
  setSubsidyAccountsProcessing(SubsidyAccountsProcessing: any) {
    this.SubsidyAccountsProcessing = SubsidyAccountsProcessing
  }

  @Action({ commit: 'setSubsidyAccounts' })
  async fetchSubsidyAccounts() {
    if (this.SubsidyAccounts.length === 0) {
      try {
        const result = await axios.get(apiURL + '/subsidyAccounts')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SubsidyAccounts
    }
  }

  @Action({ commit: 'setSubsidyAccountsProduction' })
  async fetchSubsidyAccountsProduction() {
    if (this.SubsidyAccountsProduction.length === 0) {
      try {
        const result = await axios.get(apiURL + '/subsidyAccounts?subset=SUBSIDY')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SubsidyAccountsProduction
    }
  }

  @Action({ commit: 'setSubsidyAccountsProcessing' })
  async fetchSubsidyAccountsProcessing() {
    if (this.SubsidyAccountsProcessing.length === 0) {
      try {
        const result = await axios.get(apiURL + '/subsidyAccounts?includeVirtual=true')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.SubsidyAccountsProcessing
    }
  }
}
