import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchSegmentationRelationStore extends VuexModule {
  SegmentationRelation_relationDate: string | null = null
  SegmentationRelation_status: number | null = null
  SegmentationRelation_identOut: number | null = null
  SegmentationRelation_identin: number | null = null
  SegmentationRelation_feeding: string | null = null
  SegmentationRelation_id: number | null = null

  items: TermItemDefinition[] = [
    { key: 'SegmentationRelation_relationDate', operation: ':' },
    { key: 'SegmentationRelation_status', operation: ':' },
    { key: 'SegmentationRelation_identOut', operation: ':' },
    { key: 'SegmentationRelation_identin', operation: ':' },
    { key: 'SegmentationRelation_feeding', operation: ':' },
    { key: 'SegmentationRelation_id', operation: ':' }
  ]
}
