import { Module, VuexModule, Mutation /*, Action */ } from 'vuex-module-decorators'

/* https://github.com/championswimmer/vuex-module-decorators */

import { Person } from '@/domain/person'
import { personsService } from '@/services/persons'

@Module
export default class PersonsStore extends VuexModule {
  applicationUser: Object = {}
  persons: Person[] = []
  selectedPersonId = 0
  selectedPerson: any = {}
  selectedApplicationUserId = 0
  selectedApplicationUserName = ''
  originLegalEntityId = 0
  domSwitchLegalEntity: any = {}

  @Mutation
  setApplicationUser(applicationUser: {}) {
    this.applicationUser = applicationUser
  }

  @Mutation
  setPersons(persons: []) {
    this.persons = persons
  }

  @Mutation
  setDomain(personId: number) {
    /* only for direct connections to LegalEntity, do not use this for domain-switching */
    this.selectedPersonId = personId
    if (!this.selectedApplicationUserId) {
      // only set originLegalEntityId when the user is not in userswitch mode
      this.originLegalEntityId = personId
    }
    this.selectedPerson = this.persons.filter((pers) => pers.id == personId)[0]
  }

  @Mutation
  setSelectedApplicationUser(selectedApplicationUser: any) {
    // test if name is digits only, if yes, display email instead
    this.selectedApplicationUserName = /^\d+$/.test(selectedApplicationUser.name) ? selectedApplicationUser.email : selectedApplicationUser.name
    this.selectedApplicationUserId = selectedApplicationUser.id
    /* only for direct connections to LegalEntity, do not use this for domain-switching */
    if (selectedApplicationUser.id) {
      // copy to domainswitch
      this.originLegalEntityId = 1
      this.selectedPersonId = selectedApplicationUser.allowedLegalEntities[0].id
      this.domSwitchLegalEntity = { ...this.selectedPerson }
      this.selectedPerson = {}
      personsService.legalEntities = selectedApplicationUser.allowedLegalEntities
      this.persons = selectedApplicationUser.allowedLegalEntities
    } else {
      //restore from domainswitch
      this.selectedPersonId = 1
      this.originLegalEntityId = 1
      this.selectedPerson = { ...this.domSwitchLegalEntity }
      this.domSwitchLegalEntity = {}
      this.persons = []
    }
  }

  @Mutation
  switchDomain(legalEntity: any) {
    this.domSwitchLegalEntity = legalEntity
    this.selectedPersonId = legalEntity.legalEntityId
  }

  @Mutation
  resetDomain() {
    this.selectedPersonId = this.originLegalEntityId
  }

  get getSelectedPerson(): any {
    return this.selectedPerson
  }

  get getSelectedPersonId(): number {
    return this.selectedPersonId
  }

  get getSelectedApplicationUserId(): number {
    return this.selectedApplicationUserId
  }

  get hasDomainSwitch() {
    return this.selectedPersonId != this.originLegalEntityId
  }

  get getOriginId(): number {
    return this.originLegalEntityId
  }

  get getDomainName(): string {
    let legalEntityName = ''
    if (this.selectedPersonId === this.originLegalEntityId) {
      legalEntityName = this.selectedPerson?.name1
      if (this.selectedPerson?.name2) legalEntityName += ' ' + this.selectedPerson?.name2
    } else {
      legalEntityName = this.domSwitchLegalEntity.legalEntityName1
      if (this.domSwitchLegalEntity.legalEntityName2) legalEntityName += ' ' + this.domSwitchLegalEntity.legalEntityName2
    }
    return legalEntityName
  }
}
