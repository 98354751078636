//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios, { showError } from '@/services/axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

/* 
computed: {
    ...mapGetters('CountryRegions', ['cantons'])
    },
methods:{
    ...mapActions('CountryRegions', ['fetchCountryRegions']),
}
mounted() {   
  this.fetchCountryRegions()
*/

@Module({ namespaced: true })
export default class CountryRegionsStore extends VuexModule {
  CountryRegions: any[] = []
  CountryRegionsCH: any[] = []

  get countryRegions() {
    return this.CountryRegions
  }
  get countryRegionsCH() {
    return this.CountryRegionsCH
  }

  @Mutation
  setCountryRegions(CountryRegions: any) {
    this.CountryRegions = CountryRegions
  }
  @Mutation
  setCountryRegionsCH(CountryRegionsCH: any) {
    this.CountryRegionsCH = CountryRegionsCH
  }

  @Action({ commit: 'setCountryRegions' })
  async fetchCountryRegions() {
    if (this.CountryRegions.length === 0) {
      try {
        const result = await axios.get(apiURL + '/countryRegions')
        return await result.data.filter((reg: { name: string }) => reg.name != 'CH_NONE')
      } catch (e) {
        showError(e)
      }
    } else {
      return this.CountryRegions
    }
  }
  @Action({ commit: 'setCountryRegionsCH' })
  async fetchCountryRegionsCH() {
    if (this.CountryRegionsCH.length === 0) {
      try {
        const result = await axios.get(apiURL + '/countryRegions/CH')
        return await result.data
      } catch (e) {
        showError(e)
      }
    } else {
      return this.CountryRegionsCH
    }
  }
}
