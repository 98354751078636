import { vueApp } from '@/main'

const Format = {
  install(Vue: any, options: any) {
    Vue.prototype.formatNumber = (number: number, precision: number) => {
      const numberFormatter = new Intl.NumberFormat('de-CH', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      })
      return numberFormatter.format(number)
    }

    Vue.prototype.formatChDate = (date: string) => {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    }

    // Wandelt ISO-Date zu Java localDateTime
    Vue.prototype.formatDateTime = (date: string) => {
      if (!date) return null
      const dateTime = vueApp.$moment(date).format('YYYY-MM-DDT00:00:00')
      return dateTime
    }

    // Takes various dates (ISO-Date, epoch, YYYY-MM-DD 00:00:00.123) and converts to chDateTimeFormat
    Vue.prototype.formatChDateTime = (dateTime: any) => {
      // console.log(dateTime, 'dateTime')
      // console.log(typeof dateTime, 'type of dateTime')
      if (typeof dateTime === 'number' || dateTime instanceof Number) {
        // console.log(dateTime, 'dateTime is number')
        dateTime = dateTime.toString()
        // console.log(dateTime, 'dateTime is string now')
      }
      if (typeof dateTime === 'string' || dateTime instanceof String) {
        dateTime = dateTime.toString()
        if (dateTime.length == 13 && /^\d+$/.test(dateTime)) {
          // console.log(dateTime, 'dateTime number converting')
          dateTime = Vue.prototype.formatDateTime(new Date(Number(dateTime))) // epoch format
        }
        if (!dateTime.includes('T')) dateTime = [dateTime.slice(0, 10), 'T', dateTime.slice(11, 19)].join('')
        if (dateTime.length == 20) dateTime = dateTime.slice(0, 17)
        const [date, time] = dateTime.split('T')
        const [year, month, day] = date.split('-')
        return `${day}.${month}.${year} ${time}`
      }
      // console.log(null, 'returning dateTime null')
      return null
    }

    // Takes various dates (ISO-Date, epoch, YYYY-MM-DD 00:00:00.123) and converts to chDateFormat
    Vue.prototype.formatDateTimeToChDate = (dateTime: string) => {
      dateTime = Vue.prototype.formatChDateTime(dateTime)
      if (typeof dateTime === 'string' && dateTime.length > 10) {
        dateTime = dateTime.substring(0, 10)
      }
      return dateTime
    }

    Vue.prototype.formatDate = (date: string) => {
      if (!date) return null
      const dateNew = vueApp.$moment(date).format('YYYY-MM-DD')
      return dateNew
    }
    Vue.prototype.formatPhone = (phone: String) => {
      if (!phone || typeof phone === 'function') return null
      return phone.replace(/[\s-]+/g, '')
    }
  }
}

export default Format
