import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class SearchRebookingPeriodStore extends VuexModule {
  animalTypeId: number | null = null
  validFrom: string | null = null
  validUntil: string | null = null

  items: TermItemDefinition[] = [
    { key: 'animalTypeId', operation: ':', itemType: 'number' },
    { key: 'validFrom', operation: '>=' },
    { key: 'validUntil', operation: '<=' }
  ]
}
