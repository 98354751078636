import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchWarningStore', ['hasProcessingFormValidationWarnings'])
    },
 */
@Module({ namespaced: true })
export default class SearchWarningStore extends VuexModule {
  ProcessingFormValidationWarnings: number | null = null

  items: TermItemDefinition[] = [{ key: 'ProcessingFormValidationWarnings', operation: ':' }]
}
